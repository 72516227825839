import {useEffect, useRef, useState} from "react";
import Config from "../Config";

interface Props {
    zoom?: number;
    lat?: number | undefined | null;
    lon?: number | undefined | null;
    address?: string;
    onDragEnd?: (lat: number, lon: number) => void;
}
const PositionInput = (props: Props) => {
    
    const [mapInitialised, setMapInitialised] = useState<boolean>(false);
    const mapRef = useRef<any>(null);
    const markerRef = useRef<any>(null);
    const geocoder = useRef(new google.maps.Geocoder())

    const initializeMap = async () => {
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

        mapRef.current = new Map(document.getElementById('map') as HTMLElement, {
            center: { lat: 56.26392, lng: 11},
            zoom: 6.5,
            mapId: Config.googleMaps.mapId,
        });

        setMapInitialised(true);
    }

    useEffect(() => {
        initializeMap();
    }, []);
    
    const moveCursor = async () => {
        let lat;
        let lon;
        let zoomLevel = 18;

        if (props.lat && props.lon) {
            lat = props.lat;
            lon = props.lon;
        }
        else if (props.address) {
            let position = await findAddress(props.address);
            lat = position.lat;
            lon = position.lon;

            props.onDragEnd && props.onDragEnd(lat, lon);
        }

        if (!lat || !lon) {
            lat = 56.26392;
            lon = 11;
            zoomLevel = 6.5;
        }

        placeMarker(lat, lon);

        mapRef.current.setCenter({ lat: lat, lng: lon })
        mapRef.current.setZoom(zoomLevel)
    }

    const findAddress = async (address: string) => {
        let geocoderResponse = await geocoder.current.geocode( { 'address': address})

        let location = geocoderResponse.results[0].geometry.location;
        let lat = location.lat();
        let lon = location.lng();

        props.onDragEnd && props.onDragEnd(lat, lon);

        return { lat, lon }
    }

    const placeMarker = (lat: number, lon: number) => {
        if (!!markerRef.current) {
            markerRef.current.setMap(null);
        }

        markerRef.current = new window.google.maps.Marker({
            map: mapRef.current,
            position: {
                lat: lat,
                lng: lon
            },
            draggable: true,
        })

        markerRef.current.addListener("dragend", (x: any) => props.onDragEnd && props.onDragEnd(x.latLng.lat(), x.latLng.lng()))
    }

    useEffect(() => {
        if (mapInitialised) {
            moveCursor();
        }
    }, [mapInitialised, props.lat, props.lon, props.address]);
    
    return (
        <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', zIndex: 999, paddingTop: 10, pointerEvents: 'none'}}>
                <button type={"button"} style={{backgroundColor: 'white', padding: "0px 17px", border: 0, height: 40, boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px', cursor: 'pointer', pointerEvents: 'all', fontSize: 18, borderRadius: 2}} onClick={() => findAddress(props.address!)}>Flyt nål til adressen</button>
            </div>
            <div style={{minHeight: 500, height: '100%', width: '100%'}} id="map" />
        </div>
    )

}

export default PositionInput