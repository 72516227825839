import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth } from "FirebaseSetup/auth";
import PageLoader from "Components/PageLoader";
import { NewPasswordForm } from "Features/Authentication";
import Flex from "Components/BaseComponents/Flex";

const AuthActions = () => {
    
    let query = new URLSearchParams(useLocation().search);
    let mode = query.get('mode');
    let oobCode = query.get('oobCode') ?? '';
    
    const [codeValidated, setCodeValidated] = useState<boolean>(false);
    
    
    useEffect(() => {
        if (mode === 'resetPassword' && !codeValidated) {
            auth.verifyPasswordResetCode(oobCode).then(() => {
                setCodeValidated(true);
            })
        }
    }, [mode, oobCode, codeValidated]);
    
    if (!codeValidated) {
        return <PageLoader shown={true}><div></div></PageLoader>
    }
    
    return (
        <Flex style={{height: '100vh'}} $justifyCenter $alignCenter>
            <div style={{width: 550}}>
                <NewPasswordForm oobCode={oobCode} />
            </div>
        </Flex>
    )
}

export default AuthActions
