import {SchemaDefinitionDto} from "../Services/SchemaDefinitionDto";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {SchemaDto} from "../Services/SchemaDto";
import SchemaQuestionBuilder from "./SchemaQuestionBuilder";
import Button from "Components/Button";
import Flex from "../../../Components/BaseComponents/Flex";
import {useTranslation} from "react-i18next";

interface Props {
    schema?: Partial<SchemaDto>;
    schemaDefinition: SchemaDefinitionDto;
    onSave: (schema: SchemaDto) => void;
    isLoading?: boolean;
}
const SchemaFormBuilder = (props: Props) => {

    const { t } = useTranslation();
    
    const { schemaDefinition, schema } = props;
    
    const {reset, control, handleSubmit} = useForm<SchemaDto>();
    
    useEffect(() => {
        if (schema) {
            reset(schema)
        }
    }, [schema, reset])
    
    const save = async (data: SchemaDto) => {
        data = {
            ...schema, 
            ...data,
            schemaDefinitionId: schemaDefinition.id
        }
        
        props.onSave(data);
    }
    
    const renderSchema = (schemaDefinition: SchemaDefinitionDto) => {
        return (
            <>
                {schemaDefinition.schemeQuestions.map((question) => (
                    <React.Fragment key={question.order}>
                        <Controller name={`answers.${question.order - 1}.id`} defaultValue={undefined} control={control} render={() => (<></>)} />
                        <Controller name={`answers.${question.order - 1}.order`} defaultValue={question.order} control={control} render={() => (<></>)} />
                        <Controller name={`answers.${question.order - 1}.value`} defaultValue={''} control={control} render={({field}) => (
                            <SchemaQuestionBuilder question={question} value={field.value} onChange={field.onChange} />
                        )} />
                        
                    </React.Fragment>
                ))}

                <Flex $justifyEnd>
                    <Button loading={props.isLoading} disabled={props.isLoading} onClick={handleSubmit(save)}>{t('save')}</Button>
                </Flex>
            </>
        )
    }
    
    return renderSchema(schemaDefinition)
}


export default SchemaFormBuilder