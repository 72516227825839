import { H1 } from "Components/BaseComponents/Heading";
import React from "react";
import { useTranslation } from "react-i18next";
import Menu from "Layouts/Menu";
import Button from "Components/Button";
import Flex from "Components/BaseComponents/Flex";
import {useNavigate} from "react-router-dom";
import StyledInput from "Components/BaseComponents/StyledInput";
import Gap from "Components/BaseComponents/Gap";
import CamerasList from "../../Features/Cameras/Components/CamerasList";
import {useCameras} from "../../Features/Cameras/Services/CameraApi";
import useQueryParams from "../../Hooks/UseQueryParams";
import Pagination from "../../Components/Pagination";

const Index = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ filter, setFilter ] = useQueryParams({page: 0, pageSize: 30, sort: "serialNumber", sortDirection: 'ASC'});

    const { data, isLoading } = useCameras(filter, true);

    const setSorting = (columnKey?: string) => {
        if (!columnKey) {
            return;
        }

        if (filter?.sort === columnKey) {
            setFilter({...filter, sortDirection: filter?.sortDirection === 'ASC' ? 'DESC' : 'ASC'});
        } else {
            setFilter({...filter, sort: columnKey, sortDirection: 'ASC'});
        }
    }
    
    return (
        <>
            <Flex $spaceBetween>
                <H1>{t('cameras')}</H1>
                <div>
                    <Button onClick={() => navigate("create")} color={"secondary"}>{t('createCamera')}</Button>
                </div>
            </Flex>
            
            <Gap $vertical>
                <Menu menuItems={[
                    {
                        label: t('menu.cameras'),
                        to: "/cameras",
                        exact: true
                    },
                    {
                        label: t('menu.cameraLocations'),
                        to: "/cameras/locations",
                    },
                ]}  />
    
                <StyledInput $block placeholder={t('search')} onChange={e => setFilter({search: e.currentTarget.value})} />
                
                <CamerasList data={data?.items} isLoading={isLoading} setSorting={setSorting} />

                <Pagination paginatedList={data} onPageChange={newPage => setFilter({page: newPage})} />
            </Gap>
        </>
    )
}


export default Index