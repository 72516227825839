import * as RadixCheckbox from '@radix-ui/react-checkbox';
import styled, { css } from 'styled-components';
import Flex from './BaseComponents/Flex';
import {FaCheck} from "react-icons/fa6";
import StyledLabel from "./BaseComponents/Label";
import React from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    checked?: boolean;
    onCheckedChange?: (value: boolean) => void;
    defaultChecked?: boolean;
    name: string;
    label?: string;
    rules?: any;
    errors?: any;
    secondaryLabel?: string;
    hasError?: boolean;
}

const Checkbox = (props: Props) => {

    const label = () => (
        <Flex $spaceBetween $alignCenter>
            <StyledLabel style={{paddingRight: 10}} htmlFor={props.name} children={props.label}/>
            {props.secondaryLabel && <StyledLabel style={{fontSize: '0.8rem', color: '#E91D25'}} children={props.secondaryLabel} />}
        </Flex>
    )
    
    return (
        <Flex $spaceBetween $alignCenter>
            {label()}
            <StyledRoot checked={props.checked} onCheckedChange={props.onCheckedChange} id={props.name} $error={props.hasError ?? false}>
                <RadixCheckbox.Indicator>
                    <FaCheck/>
                </RadixCheckbox.Indicator>
            </StyledRoot>
        </Flex>
    )
}

const StyledRoot = styled(RadixCheckbox.Root)<{$error: boolean}>`
  border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.colors.border};
  background-color: white;
  width: ${props => props.theme.spacing(3)};
  height: ${props => props.theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${props => props.theme.spacing(.5)};

  ${props => props.$error && css `
        border-color: ${props => props.theme.colors.danger};
    `}
`

export default Checkbox