import {SchemaFieldDto, SchemaQuestionDefinitionDto} from "../Services/SchemaDefinitionDto";
import Textarea from "Components/Textarea";
import React from "react";
import Checkbox from "Components/Checkbox";
import Button from "Components/Button";
import Flex from "Components/BaseComponents/Flex";
import Gap from "Components/BaseComponents/Gap";
import StyledLabel from "Components/BaseComponents/Label";

interface Props {
    question: SchemaQuestionDefinitionDto,
    value: string,
    onChange: any
}
const SchemaQuestionBuilder = (props: Props) => {

    const { question, value, onChange } = props;

    const createDefaultValueArray = (): string => {
        const defaultValue = [new Array(question.fields.length)];

        return JSON.stringify(defaultValue);
    }

    const getValueArray = (): any[] => {
        let internalValue = value;

        if (!internalValue) {
            internalValue = createDefaultValueArray();
        }

        return JSON.parse(internalValue);
    }

    const internalOnChange = (order: number, valueIndex: number, newValue: string): void => {
        let internalValue = getValueArray();
        
        internalValue[valueIndex][order - 1] = newValue;

        onChange(JSON.stringify(internalValue))
    }

    const addField = (e: any): void => {
        e.preventDefault();
        
        let intervalValue = getValueArray();

        intervalValue.push(new Array(question.fields.length));

        onChange(JSON.stringify(intervalValue))
    }

    const removeField = (e: any, index: number) => {
        e.preventDefault();
        
        let intervalValue = getValueArray();

        const newValues = intervalValue.filter((value, i) => i !== index)

        onChange(JSON.stringify(newValues))
    }
    
    const shouldShowRemoveField = (index: number) => {
        return question.isExpandable && index >= (question.minimumValues ?? 0)
    }
    
    const shouldShowField = (field: SchemaFieldDto, index: number) => {
        return field.visibleWhenFieldOrder === null || (field.visibleWhenFieldOrder && getValueArray()[index][field.visibleWhenFieldOrder - 1] === field.visibleWhenFieldValue) 
    }
    
    
    const renderField = (field: SchemaFieldDto, index: number, value: string, fieldIndex: number) => {
        if (!shouldShowField(field, index)) {
            return <></>
        } 
        
        switch (field.type) {
            case 'Headline':
                return (
                    <h2>{field.text}</h2>
                )
            case 'TextArea':
                return (
                    <>
                        {!!field.text && <StyledLabel children={`${field.text} ${field.required ? '*' : ''}`} />}
                        <Textarea rows={2} defaultValue={value} onChange={event  => internalOnChange(field.order, index, event.currentTarget.value)} hasError={field.required && (!value || !value[fieldIndex])}  />
                    </>
                )
            case 'RadioButtons':
                return (
                    <Flex $spaceBetween $alignCenter>
                        {!!field.text && <StyledLabel children={`${field.text} ${field.required ? '*' : ''}`} />}
                        <Gap>
                            {field.possibleValues!.map((possibleValue, possibleValueIndex) => (
                                <Checkbox hasError={field.required && (!value || !value[fieldIndex])} checked={possibleValue === value} name={"radio-" + field.order + "-" + index} label={possibleValue} onCheckedChange={() => internalOnChange(field.order, index, possibleValue)} />
                            ))}
                        </Gap>
                    </Flex>
                )
        }
    }
    
    return (
        <div>
            {!!question.title && <StyledLabel children={question.title} />}
            {getValueArray().map((value, index) => (
                <div>
                    <Flex $spaceBetween>
                        <div style={{flex: 1}}>
                            {question.fields.map((field, fieldIndex) => renderField(field, index, value[fieldIndex], fieldIndex))}
                        </div>
                    </Flex>
                    {shouldShowRemoveField(index) && (
                        <div style={{paddingTop: 10}}>
                            <Button color={"danger"} onClick={(e) => removeField(e, index)}>
                                -
                            </Button>
                        </div>
                    )}
                </div>
            ))}
            {question.isExpandable && (
                <Flex $justifyEnd>
                    <Button type={"button"} onClick={addField} >
                        +
                    </Button>
                </Flex>
            )}
        </div>
    )
}

export default SchemaQuestionBuilder