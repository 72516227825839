import Flex from "./BaseComponents/Flex";
import StyledLabel from "./BaseComponents/Label";
import React, {useMemo} from "react";
import styled, { css } from "styled-components";
import {FieldError} from "react-hook-form";

interface Props extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'placeholder'> {
    placeholder?: string | null | undefined;
    error?: FieldError | undefined;
}
const Select = React.forwardRef((props: Props, ref: any) => {

        const errorMessage = useMemo(() => {
        if (props.error?.message) {
            return props.error?.message;
        }
    }, [props.error])
    
    return (
        <Flex $spaceBetween>
            <Flex $spaceBetween $alignCenter>
                <StyledLabel htmlFor={"name"} children={props.placeholder} />
                {!!props.error && <StyledLabel style={{fontSize: '0.8rem'}} children={errorMessage} />}
            </Flex>
            <StyledSelect
                $error={!!props.error}
                ref={ref}
                {...props}
                placeholder={props.placeholder ?? ''}
            >
                
            </StyledSelect>
        </Flex>
    )
})


const StyledSelect  = styled.select<{$error?: boolean }>`
  all: unset;
  box-sizing: border-box;
  border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.colors.primaryText};
  padding: ${props => props.theme.spacing(1)};
  line-height: 1.5;
  overflow: hidden;
  min-width: 400px;
  width: 400px;
  max-width: 400px;
  ${props => props.$error && css `
        border-color: ${props => props.theme.colors.danger};
    `}
`


export default Select