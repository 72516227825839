import { Navigate, Route, Routes } from "react-router-dom";
import Blocks from "./Blocks";
import BlocksServices from "./BlocksServices";
import Map from './Map';
import ShowBlockService from "./ShowBlockService";
import BlocksServicesForBlock from "./BlocksServicesForBlock";
import { AccessLevel } from "Features/Users/Services/UserDto";
import { useAuthProvider } from "Providers/AuthProvider";

const CustomerViewRoutes = () => {
    
    const { getCustomClaim } = useAuthProvider();

    return (
        <Routes>
            <Route path={"/map"} element={<Map />}/>
            {(getCustomClaim('AccessLevel') === AccessLevel.Customer) && (
                <>
                    <Route path={"/blocks/services/report/:id"} element={<ShowBlockService />}/>
                    <Route path={"/blocks/services/:blockId"} element={<BlocksServicesForBlock />}/>
                    <Route path={"/blocks/services"} element={<BlocksServices />}/>
                </>
            )}
            <Route path={"/blocks"} element={<Blocks />}/>
            
            <Route path={"*"} element={<Navigate to={"/map"}/>}/>
        </Routes>
    )
}

export default CustomerViewRoutes
