import List from "Components/List";
import { useTranslation } from "react-i18next";
import Gap from "../../../Components/BaseComponents/Gap";
import {BlockDto} from "../Services/BlockDto";
import {ContactApi} from "../../Customers";
import {useBlockTypes} from "../Services/BlockTypeApi";
import {useBlockSubscriptions} from "../Services/BlockSubscriptionApi";
import Clickable from "../../../Components/BaseComponents/Clickable";
import {FaEye} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { AccessLevel } from "Features/Users/Services/UserDto";
import { useAuthProvider } from "Providers/AuthProvider";

interface Props {
    data?: BlockDto[];
    isLoading: boolean;
}
const CustomerViewBlocksList = (props: Props) => {

    const { t } = useTranslation();
    const { getCustomClaim } = useAuthProvider();

    const navigate = useNavigate();
    
    const { data: contacts } = ContactApi.useCustomerViewContacts();
    const { data: blockTypes } = useBlockTypes()
    const { data: blockSubscriptions } = useBlockSubscriptions()

    return (
        <List<BlockDto> data={props.data} loading={props.isLoading} columns={[
            {
                title: t('serialNumber'),
                textRender: (record) => record.serialNumber
            },
            {
                title: t('contacts'),
                textRender: (record) => contacts?.items.filter(x =>record.contacts?.includes(x.id))?.map(x => x.name).join(', ') ?? ""
            },
            {
                title: t('address'),
                textRender: (record) => `${record.address} ${record.city} ${record.zipCode}`
            },
            {
                title: t('blockType'),
                textRender: (record) => blockTypes?.items.find(x => x.id === record.blockTypeId)?.name ?? ""
            },
            {
                title: t('blockSubscription'),
                textRender: (record) => blockSubscriptions?.items.find(x => x.id === record.blockSubscriptionId)?.name ?? ""
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                    <Gap>
                        {(getCustomClaim('AccessLevel') === AccessLevel.Customer) && (
                            <Clickable onClick={() => navigate(`/blocks/services/${record.id}`)}><FaEye />{t('showServices')}</Clickable>
                        )}
                    </Gap>
                )
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default CustomerViewBlocksList