import Flex from "Components/BaseComponents/Flex";
import ControlledInput from "Components/ControlledInput";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import {Controller, useForm } from "react-hook-form";
import useToasts from "Hooks/UseToasts";
import React, {useEffect} from "react";
import Select from "../../../Components/Select";
import CheckboxWithLabel from "../../../Components/CheckboxWithLabel";
import ContentLoader from "Components/ContentLoader";
import PositionInput from "../../../Components/PositionInput";
import DateTimePicker from "../../../Components/DateTimePicker";
import {CameraDto, MutateCameraDto} from "../Services/CameraDto";
import {useCameraLocations} from "../Services/CameraLocationApi";
import {useMutateCamera} from "../Services/CameraApi";
import {useUsers} from "../../Users/Services/UsersApi";
import {AccessLevel} from "../../Users/Services/UserDto";
import ControlledTextarea from "Components/ControlledTextarea";
import {DawaAutocompleteModel} from "../../../Apis/Models/DawaAutocompleteModel";
import Dropdown, {DropdownItem} from "../../../Components/Dropdown";
import {useAddressSearch} from "../../../Hooks/UseAddressSearch";
import { StyledForm } from "Components/BaseComponents/StyledForm";
import StyledLabel from "../../../Components/BaseComponents/Label";
import Gap from "../../../Components/BaseComponents/Gap";

interface Props {
    entity?: CameraDto;
    onSubmit?: () => void;
}
const CameraForm = (props: Props) => {

    const { t } = useTranslation();
    const { showSuccessToast } = useToasts();

    const { data: employees, isLoading: isFetchingEmployees } = useUsers();
    const { data: cameraLocations, isLoading: isFetchingCameraLocations } = useCameraLocations();
    
    const { 
        getValues, 
        setValue,
        control,
        register,
        handleSubmit, 
        reset,
        watch,
        formState: { 
            errors
        } 
    } = useForm<MutateCameraDto>();

    const { searchAddresses, addressResults, clear } = useAddressSearch();
    const { mutateAsync, isLoading } = useMutateCamera();

    useEffect(() => {
        if (register) {
            register('latitude');
            register('longitude');
        }
    }, [register]);
    
    useEffect(() => {
        if (!!props.entity) {
            reset(props.entity)
        }
    }, [reset,  props.entity]);
    
    const onSubmit = async (block: MutateCameraDto) => {
        await mutateAsync(block);
        
        showSuccessToast(t('cameraSaved'));
        
        props.onSubmit?.();
    }

    const selectAddress = (address: DawaAutocompleteModel) => {
        setValue('address', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('zipCode', address.adresse.postnr);
        setValue('city', address.adresse.postnrnavn);
        clear();
    }
    
    return (
        <ContentLoader shown={(isFetchingCameraLocations || isFetchingEmployees)}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <ControlledInput placeholder={t('serialNumber')} error={errors.serialNumber} {...register('serialNumber', {required: true})} />

                <Controller control={control} name={"installationDate"}  render={({ field, fieldState }) => (
                    <DateTimePicker name={field.name} label={t('installationDate')} error={fieldState.error} value={field.value} onChange={field.onChange}></DateTimePicker>
                )} />

                <Controller control={control} name={"removalDate"} render={({ field, fieldState }) => (
                    <DateTimePicker name={field.name} label={t('removalDate')} error={fieldState.error} value={field.value} onChange={field.onChange}></DateTimePicker>
                )} />

                <Flex $spaceBetween $alignCenter>
                    <StyledLabel children={t('address')} />
                    <Dropdown content={
                        <>
                            {addressResults?.map((result, index) => (
                                <DropdownItem key={result.adresse.id} onClick={() => selectAddress(result)}>
                                    <div>{result.tekst}</div>
                                </DropdownItem>
                            ))}
                        </>
                    }>
                        <ControlledInput error={errors.address} {...register('address', {required: true})} onChange={(e) => searchAddresses(e.currentTarget.value)}  />
                    </Dropdown>
                </Flex>
                
                <ControlledInput placeholder={t('zipCode')} error={errors.zipCode} {...register('zipCode')} />
                <ControlledInput placeholder={t('city')} error={errors.city} {...register('city')} />

                <ControlledTextarea placeholder={t('notes')} error={errors.notes} {...register('notes')} />

                <Select placeholder={t('cameraLocation')} error={errors.locationId} {...register('locationId')} >
                    <option value={''}>{t('select')}</option>
                    {cameraLocations?.items.map(location => (
                        <option key={location.id} value={location.id}>{location.name}</option>
                    ))}
                </Select>

                <Select placeholder={t('employee')} error={errors.employeeId} {...register('employeeId', {required: true})} >
                    <option value={''}>{t('select')}</option>
                    {employees?.items.filter(x => x.accessLevel >= AccessLevel.Employee)?.map(employee => (
                        <option key={employee.id} value={employee.id}>{employee.name}</option>
                    ))}
                </Select>
                
                <CheckboxWithLabel defaultChecked={true} name={"active"} label={t('active')} placeholder={t("active")} control={control} />

                <ControlledTextarea placeholder={t('history')} error={errors.history} {...register('history')} />

                <Gap $vertical>
                    <StyledLabel>{t('location')}</StyledLabel>
                    <PositionInput address={watch('address') ? `${watch('address')} ${watch('zipCode')}` : ''} lat={watch('latitude') ? parseFloat(getValues('latitude')) : null } lon={getValues('longitude') ? parseFloat(getValues('longitude')) : null} onDragEnd={(lat, lon) => {
                        setValue('latitude', lat.toString());
                        setValue('longitude', lon.toString());
                    }} />
                </Gap>
                
                <Flex $justifyEnd>
                    <Button loading={isLoading} type={"submit"}>
                        {t('save')}
                    </Button>
                </Flex>
            </StyledForm>
        </ContentLoader>
    )

}

export default CameraForm