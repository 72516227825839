import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ContentLoader from "Components/ContentLoader";
import CameraForm from "../../Features/Cameras/Components/CameraForm";
import {useCamera} from "../../Features/Cameras/Services/CameraApi";

const Edit = () => {

    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data, isLoading } = useCamera(parseInt(id!))
    
    const navigate = useNavigate();
    
    return (
        <ContentLoader shown={isLoading}>
            <H1 style={{paddingBottom: 10}}>{t('editCamera')}</H1>

            <CameraForm entity={data} onSubmit={() => navigate(-1)} />
        </ContentLoader>
    )

}

export default Edit