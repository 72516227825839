import React from "react";
import {Controller, ControllerFieldState} from "react-hook-form";
import Input from "./Input";

interface ControllerProps extends React.InputHTMLAttributes<HTMLInputElement> {
    control: any;
    name: string;
    placeholder?: string | undefined | any;
    defaultValue?: string;
    rules: any
}
const ControllerInput = (props: ControllerProps) =>  {
        
    const errorMessage = (fieldState: ControllerFieldState) => {
        if (fieldState?.error?.message) {
            return fieldState?.error?.message;
        }
    }

    return (
        <Controller control={props.control} name={props.name} render={({ field, fieldState, formState} ) => (
            <Input hasError={!!fieldState.error} secondaryLabel={errorMessage(fieldState)} {...props} {...field} onChange={(e) => {
                props.onChange && props.onChange(e);
                field.onChange(e);
            }} onBlur={(event) => {
                props.onBlur && props.onBlur(event);
                field.onBlur();
            }} />
        )} />
    )
}

export default ControllerInput;