import List from "Components/List";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FaDownload, FaPen, FaTrash} from "react-icons/fa6";
import Clickable from "Components/BaseComponents/Clickable";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import Gap from "../../../Components/BaseComponents/Gap";
import useToasts from "../../../Hooks/UseToasts";
import {useBlocks} from "../Services/BlockApi";
import {useUsers} from "../../Users/Services/UsersApi";
import { BlockServiceDto } from "../Services/BlockServiceDto";
import moment from "moment/moment";
import {downloadBlockServiceReport, useDeleteBlockService} from "../Services/BlockServiceApi";

interface Props {
    data?: BlockServiceDto[];
    isLoading: boolean;
}
const BlockServiceList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast } = useToasts();

    const { data: blocks } = useBlocks();
    const { data: employees } = useUsers();
    
    const { mutateAsync: deleteBlockServiceMutation, isLoading: isDeleting } = useDeleteBlockService();
    
    const deleteBlock = async (block: BlockServiceDto) => {
        await deleteBlockServiceMutation(block);
        
        showSuccessToast(t('blockServiceDeleted'));
    }
    
    const getBlockAddress = (blockService: BlockServiceDto) => {
        let block = blocks?.items.find(x => x.id === blockService.blockId);
        
        if (!block) {
            return '';
        }
        
        return `${block.address}, ${block.zipCode} ${block.city}`;
    }

    const downloadReport = async (blockService: BlockServiceDto) => {
        await downloadBlockServiceReport(blockService.id!, `${t('serviceReport')}.pdf`);
    }

    return (
        <List<BlockServiceDto> data={props.data} loading={props.isLoading} columns={[
            {
                title: t('serialNumber'),
                textRender: (record) => blocks?.items.find(x => x.id === record.blockId)!.serialNumber!
            },
            {
                title: t('date'),
                textRender: (record) => moment(record.serviceDate).format('DD / MM / YYYY')
            },
            {
                title: t('employee'),
                textRender: (record) => employees?.items.find(x => x.id === record.employeeId)?.name ?? ""
            },
            {
                title: t('nextServiceDate'),
                textRender: (record) => moment(record.nextService).format('DD / MM / YYYY')
            },
            {
                title: t('address'),
                textRender: (record) => getBlockAddress(record)
            },
            {
                title: t('hasAlarm'),
                textRender: (record) => record.hasAlarm ? t('yes') : t('no')
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                        <Gap>
                            <Clickable onClick={() => downloadReport(record)}><FaDownload />{t('download')}</Clickable>
                            <Clickable onClick={() => navigate(`/blocks/services/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                            <ConfirmationDialog loading={isDeleting} onConfirm={() => deleteBlock(record)} buttonType={'danger'} title={t('areYouSure')}>
                                <Clickable><FaTrash />{t('delete')}</Clickable>
                            </ConfirmationDialog>
                        </Gap>
                    ) 
                
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default BlockServiceList