import {BlockDto} from "../Features/Blocks/Services/BlockDto";
import {CustomerDto} from "../Features/Customers/Services/CustomerDto";
import {ContactDto} from "../Features/Customers/Services/ContactDto";

export const getBlockPopupContent = (block: BlockDto, customers: CustomerDto[], contacts: ContactDto[], t: Function) => {
    let blockCustomers = customers.filter(x => block.customers?.includes(x.id))!

    let contactDivs = block.contacts?.length! > 0 ? contacts.filter(x => block.contacts?.includes(x.id)).map(contact => {
        return `<div style="font-weight: bold; padding-bottom: 5px;">${t('contactName')}: ${contact.name}</div>` +
            `<div style="padding-bottom: 5px;">${t('phone')}: <a href="tel:${contact.phone}">${contact.phone}</a></div>`
    })?.join('') : '';

    let customerDivs = blockCustomers.map(customer => `<div style="font-weight: bold; padding-bottom: 5px;">${t('customerName')}: ${customer.name}</div>` +
        `<div style="padding-bottom: 5px;">${t('phone')}: <a href="tel:${customer.phone}">${customer.phone}</a></div>` +
        `<div style="padding-bottom: 5px;">${t('email')}: <a href="mailto:${customer.email}">${customer.email}</a></div>`).join('');

    return '<div>' +
        `<div style="padding-bottom: 5px; font-weight: bold"><a style="display: flex; gap: 5px;" href="/blocks/services?search=${block.serialNumber}">${block.serialNumber}</a></div>` +
        `<div style="padding-bottom: 5px;">${block.address}, ${block.zipCode} ${block.city}</div>` +
        `${customerDivs}` +
        `${contactDivs}` +
        `<div style="padding-bottom: 5px;"><a style="display: flex; gap: 5px;" href="/blocks/edit/${block.id}"><img src="/Images/pen.png" height="15" width="15" alt="edit" /> ${t('edit')}</a></div>` +
        `<div style="padding-bottom: 10px;"><a style="display: flex; gap: 5px;" href="/blocks/services/create/block/${block.id}"><img src="/Images/file.png" height="18" width="15" alt="edit" /> ${t('createBlockService')}</a></div>` +

        `<div style="padding-bottom: 5px;"><a style="display: flex; gap: 5px;" target="_blank" href="https://www.google.com/maps/search/?api=1&query=${block.latitude},${block.longitude}">${t('openTrafficDirections')}</a></div>` +
        
        `<div style="padding-top: 10px; padding-bottom: 5px;">${block.notes}</a></div>` +
        (!!block.importantNotes ? `<div style="padding-top: 10px; padding-bottom: 5px; font-weight: bold">${block.importantNotes}</a></div>` : '') +
        "</div>";
}

