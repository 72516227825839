import { useAuthProvider } from "Providers/AuthProvider";
import {AccessLevel} from "../Services/UserDto";

const useHasAccess = (requiredAccessLevel: AccessLevel) => {

    const { getCustomClaim } = useAuthProvider();
    
    return getCustomClaim('AccessLevel') >= requiredAccessLevel;
}

export default useHasAccess