import { IUserContextDto } from "Apis/Models/IUserContextDto";
import { request } from "Apis/BaseApi";
import {useMutation, useQuery } from "@tanstack/react-query";

export const USER_CONTEXT_CACHE_KEY = 'USER_CONTEXT_CACHE_KEY'
export const useUserContext = (enabled: boolean) => {
    return useQuery<IUserContextDto, unknown>([USER_CONTEXT_CACHE_KEY], async () => {
        return await request<IUserContextDto>({
            url: `/usercontext`,
            method: 'GET',
        })}, {
        enabled,
    });
}

export const useSendResetPassword = () => {
    return useMutation(async (email: string) => {
        return await request({
            url: `/users/resetPassword`,
            method: 'POST',
            data: {
                email
            }
        })
    })
}