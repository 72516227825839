interface Config {
    baseUrl: string,
    firebaseConfig: FirebaseConfig;
    googleMaps: GoogleMapsConfig;
}

interface FirebaseConfig {
    apiKey: string,
    authDomain: string,
    projectId: string,
    appId?: string
}

interface GoogleMapsConfig {
    apiKey: string,
    mapId: string;
}


let config: Config

if (process.env.REACT_APP_ENV === 'Production') {
    config = {
        baseUrl: 'https://app.rottefaengeren.dk/app',
        firebaseConfig: {
            apiKey: "AIzaSyCKlEAbhe_UwyLpgeYdFsuTDYeFRr-MRu8",
            authDomain: "piedpiper-production-78ef5.firebaseapp.com",
            projectId: "piedpiper-production-78ef5",
        },
        googleMaps: {
            apiKey: "AIzaSyDyT0p8iI2-ojoKGLKwzHIwnOtRkAYm4UU",
            mapId: '6895c4769c09131a',
        }
    }
}
else {
    config = {
        baseUrl: 'http://localhost:3000/api',
        firebaseConfig: {
            apiKey: "AIzaSyCKlEAbhe_UwyLpgeYdFsuTDYeFRr-MRu8",
            authDomain: "piedpiper-production-78ef5.firebaseapp.com",
            projectId: "piedpiper-production-78ef5",
        },
        googleMaps: {
            apiKey: "AIzaSyDyT0p8iI2-ojoKGLKwzHIwnOtRkAYm4UU",
            mapId: '6895c4769c09131a',
        }
    }
}

export default config;
