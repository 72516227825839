import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import Button from "./Button";
import Flex from "./BaseComponents/Flex";
import Gap from './BaseComponents/Gap';
import {useState} from "react";

interface Props {
    children: any;
    onConfirm?: () => void;
    onCancel?: () => void;
    buttonType: 'default' | 'secondary' | 'danger';
    cancelText?: string | undefined;
    confirmText?: string | null;
    title: string;
    text?: string | undefined;
    loading?: boolean;
}
const ConfirmationDialog = (props: Props) => {
    
    const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    
    return (
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
            <AlertDialog.Trigger asChild>
                {props.children}
            </AlertDialog.Trigger>
            <AlertDialog.Portal>
                <Overlay />
                <Content>
                    <Flex $justifyCenter>
                        <AlertDialog.Title className="AlertDialogTitle">{props.title}</AlertDialog.Title>
                    </Flex>
                    <AlertDialog.Description className="AlertDialogDescription">
                        {props.text}
                    </AlertDialog.Description>
                    <Flex $justifyCenter>
                        <Gap>
                            <AlertDialog.Cancel asChild>
                                <Button onClick={props.onCancel} color={'secondary'}>{props.cancelText ?? t('cancel')}</Button>
                            </AlertDialog.Cancel>
                            <Button onClick={async () => {
                                await props.onConfirm?.();
                                setOpen(false);
                            }} color={props.buttonType ?? 'danger'} loading={props.loading}>{props.confirmText ?? t('confirm')}</Button>
                        </Gap>
                    </Flex>
                </Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    )
}

const Overlay = styled(AlertDialog.Overlay)`
    background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  inset: 0;
  transition: all 150ms cubic-bezier(0.16, 1, 0.3, 1);

`

const Content = styled(AlertDialog.Content)`
    background-color: white;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 90vw;
      max-width: 500px;
      max-height: 85vh;
      padding: 25px;
      transform: translate(-50%, -50%);
      transition: all 150ms cubic-bezier(0.16, 1, 0.3, 1);
`

export default ConfirmationDialog