
interface Props {
    fill?: string;
    height?: number;
    width?: number;
}
const Spinner = (props: Props) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox={"0 0 24 24"} fill={props.fill ?? "#000"} {...props} >
            <style>
                {
                    "@keyframes spinner_otJF{0%{transform:translate(12px,12px) scale(0);opacity:1}75%,to{transform:translate(0,0) scale(1);opacity:0}}.spinner_Uvk8{animation:spinner_otJF 1.6s cubic-bezier(.52,.6,.25,.99) infinite}"
                }
            </style>
            <path d="M12 12Zm0 0Z" className="spinner_Uvk8"/>
            <path
                d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm0 20a9 9 0 1 1 9-9 9 9 0 0 1-9 9Z"
                className="spinner_Uvk8"
                style={{
                    animationDelay: ".0s",
                }}
                transform="matrix(0 0 0 0 24 24)"
                
            />
            <path
                d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm0 20a9 9 0 1 1 9-9 9 9 0 0 1-9 9Z"
                className="spinner_Uvk8"
                style={{
                    animationDelay: ".15s",
                }}
                transform="matrix(0 0 0 0 24 24)"
                
            />
        </svg>
    )

}

export default Spinner