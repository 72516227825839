import {useTranslation} from "react-i18next";
import useToasts from "../../../Hooks/UseToasts";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import ControlledInput from "../../../Components/ControlledInput";
import Flex from "../../../Components/BaseComponents/Flex";
import Button from "../../../Components/Button";
import {AccessLevel, UserDto} from "../Services/UserDto";
import {useMutateUser} from "../Services/UsersApi";
import Select from "../../../Components/Select";
import {StyledForm} from "../../../Components/BaseComponents/StyledForm";

interface Props {
    user?: UserDto;
    onSubmit?: () => void;
}
const UserForm = (props: Props) => {

    const { t } = useTranslation();

    const { showSuccessToast } = useToasts();

    const { register, handleSubmit, reset, formState: { errors } } = useForm<UserDto>();

    const { mutateAsync, isLoading } = useMutateUser();

    useEffect(() => {
        if (!!props.user) {
            reset(props.user)
        }
    }, [reset,  props.user]);

    const onSubmit = async (user: UserDto) => {
        await mutateAsync(user);

        showSuccessToast(t('userSaved'));

        props.onSubmit?.();
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput placeholder={t('name')} error={errors.name} {...register('name', {required: true})} />
            
            <ControlledInput placeholder={t('email')} error={errors.email} {...register('email', {required: true})} />

            <ControlledInput placeholder={t('phone')} error={errors.phone} {...register('phone')} />

            <Select placeholder={t('userType')} error={errors.accessLevel} {...register('accessLevel', { required: true})} >
                <option value={''}>{t('select')}</option>
                <option value={AccessLevel.Admin}>{t('accessLevels.admin')}</option>
                <option value={AccessLevel.Employee}>{t('accessLevels.employee')}</option>
                <option value={AccessLevel.Customer}>{t('accessLevels.customer')}</option>
                <option value={AccessLevel.Utility}>{t('accessLevels.utility')}</option>
            </Select>

            <Flex $justifyEnd>
                <Button loading={isLoading} type={"submit"}>
                    {t('save')}
                </Button>
            </Flex>
        </StyledForm>
    )

}

export default UserForm