import styled from "styled-components";

export const StyledForm = styled.form`

  > div:not(:last-child) {
    padding: ${props => props.theme.spacing(1)};
  }

  > div:nth-child(2n + 1):not(:last-child) {
    background-color: ${props => props.theme.colors.grey};
  }
`
