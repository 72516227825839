import React from "react";
import {useCustomerViewBlocks} from "../../Features/Blocks/Services/BlockApi";
import {useBlockSubscriptions} from "../../Features/Blocks/Services/BlockSubscriptionApi";
import {BlockDto} from "../../Features/Blocks/Services/BlockDto";
import Flex from "Components/BaseComponents/Flex";
import {useTranslation} from "react-i18next";
import {H1} from "../../Components/BaseComponents/Heading";
import Gap from "Components/BaseComponents/Gap";
import StyledInput from "../../Components/BaseComponents/StyledInput";
import useQueryParams from "../../Hooks/UseQueryParams";
import MarkerMap from "../../Components/MarkerMap";

const Map = () => {

    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();
    
    const { data } = useCustomerViewBlocks(filter);
    const { data: subscriptions } = useBlockSubscriptions();

    const getMarkerColor = (block: BlockDto) => {
        return subscriptions?.items.find(x => x.id === block.blockSubscriptionId)?.color ?? 'red'
    }
    
    const getContentString = (block: BlockDto) => {
        return '<div>' +
        `<div style="padding-bottom: 5px; font-weight: bold">${block.serialNumber}</div>` +
        `<div style="padding-bottom: 5px;">${block.address}, ${block.zipCode} ${block.city}</div>` +
        `<div style="padding-bottom: 5px;"><a style="display: flex; gap: 5px;" href="/blocks/services/${block.id}"><img src="/Images/file.png" height="15" width="15" alt="edit" /> ${t('show')}</a></div>` +
        "</div>";
    }
    
    return (
        <>

            <Gap $vertical>
                <Flex $spaceBetween>
                    <H1>{t('map')}</H1>
                </Flex>
                <StyledInput $block placeholder={t('search')} defaultValue={filter?.search?.toString() ?? ''} onChange={e => setFilter({search: e.currentTarget.value})} />

                <MarkerMap
                    getMarkerTitle={block => block.serialNumber}
                    getMarkerColor={getMarkerColor}
                    getInfoWindowContent={getContentString}
                    data={data?.items}
                />
            </Gap>
        </>
    )
}

export default Map