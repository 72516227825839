import List from "Components/List";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FaPen, FaTrash} from "react-icons/fa6";
import Clickable from "Components/BaseComponents/Clickable";
import {ContactDto} from "../Services/ContactDto";
import {useCustomers} from "../Services/CustomerApi";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import Gap from "../../../Components/BaseComponents/Gap";
import { useDeleteContact } from "../Services/ContactApi";
import useToasts from "../../../Hooks/UseToasts";

interface Props {
    data?: ContactDto[];
    isLoading: boolean;
}
const ContactList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast } = useToasts();

    const { data: customers } = useCustomers();
    
    const { mutateAsync: deleteContactMutation, isLoading: isDeleting } = useDeleteContact();
    
    const deleteContact = async (contact: ContactDto) => {
        await deleteContactMutation(contact);
        
        showSuccessToast(t('contactDeleted'));
    }

    return (
        <List<ContactDto> data={props.data} loading={props.isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('customer'),
                textRender: (record) => customers?.items.find(x => x.id === record.customerId)?.name ?? ""
            },
            {
                title: t('email'),
                textRender: (record) => record.email
            },
            {
                title: t('phone'),
                textRender: (record) => record.phone
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                        <Gap>
                            <Clickable onClick={() => navigate(`/customers/contacts/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                            <ConfirmationDialog loading={isDeleting} onConfirm={() => deleteContact(record)} buttonType={'danger'} title={t('areYouSure')}>
                                <Clickable><FaTrash />{t('delete')}</Clickable>
                            </ConfirmationDialog>
                        </Gap>
                    ) 
                
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default ContactList