import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import { AccessLevel } from "../Services/UserDto";

interface ItemWithLabel {
    value: number | undefined;
    label: string;
}
export const useAccessLevels = (): {items: ItemWithLabel[], getLabel: (accessLevel: AccessLevel) => string} => {

    const { t } = useTranslation();
    
    const items = useMemo(() => [
        { value: AccessLevel.Admin, label: t('accessLevels.admin')},
        { value: AccessLevel.Employee, label: t('accessLevels.employee')},
        { value: AccessLevel.Customer, label: t('accessLevels.customer')},
        { value: AccessLevel.Utility, label: t('accessLevels.utility')},
    ], [t]);
    
    const getLabel = (accessLevel: AccessLevel): string => {
        return items.find(x => x.value === accessLevel)?.label ?? '';
    }
    
    return { items, getLabel };
    
}