import styled from "styled-components";

export const H1 = styled.h1`
  margin: 0;
  font-size: ${props => props.theme.fontSize.h1};
`

export const H2 = styled.h2`
    margin: 0;
    font-size: ${props => props.theme.fontSize.h2};
`

export const H3 = styled.h3`
    margin: 0;
    font-size: ${props => props.theme.fontSize.h3};
  
`