import Flex from "Components/BaseComponents/Flex";
import ControlledInput from "Components/ControlledInput";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {useMutateCustomer} from "../Services/CustomerApi";
import {CustomerDto, MutateCustomerDto} from "../Services/CustomerDto";
import useToasts from "Hooks/UseToasts";
import React, {useEffect} from "react";
import {useVatInformationSearch} from "../../../Hooks/UseVatInformationSearch";
import Dropdown, {DropdownItem} from "../../../Components/Dropdown";
import {VatInformationDto} from "../../../Apis/Models/VatInformationDto";
import ControllerInput from "Components/ControllerInput";
import {AccessLevel} from "../../Users/Services/UserDto";
import Select from "../../../Components/Select";
import {useMutateUser, useUsers} from "Features/Users/Services/UsersApi";
import {useAddressSearch} from "../../../Hooks/UseAddressSearch";
import {DawaAutocompleteModel} from "../../../Apis/Models/DawaAutocompleteModel";
import {StyledForm} from "../../../Components/BaseComponents/StyledForm";
import StyledLabel from "../../../Components/BaseComponents/Label";

interface Props {
    customer?: CustomerDto;
    onSubmit?: () => void;
}
const CustomerForm = (props: Props) => {

    const { t } = useTranslation();    
    
    const { showSuccessToast } = useToasts();
    
    const { setValue, register, control, handleSubmit, reset, formState: { errors } } = useForm<MutateCustomerDto>();

    const { data: users } = useUsers();
    const { mutateAsync, isLoading } = useMutateCustomer();
    const { mutateAsync: createUser, isLoading: isCreatingUser } = useMutateUser();

    const { search, results } = useVatInformationSearch();
    const { searchAddresses, addressResults, clear } = useAddressSearch();
    
    useEffect(() => {
        if (!!props.customer) {
            reset(props.customer)
        }
    }, [reset,  props.customer]);
    
    const onSubmit = async (customer: MutateCustomerDto) => {
        if (customer.customerUserId?.toString() === "-1") {
            let user = await createUser({
                name: customer.name,
                email: customer.email,
                accessLevel: AccessLevel.Customer,
            });
            
            customer.customerUserId = user.id
        }
        
        await mutateAsync(customer);
        
        showSuccessToast(t('customerSaved'));

        props.onSubmit?.();
    }
    
    const selectVatInformation = (vatInformation: VatInformationDto) => {
        setValue('name', vatInformation.name);
        setValue('vatNumber', vatInformation.vatNumber);
        setValue('address', vatInformation.street);
        setValue('zipCode', vatInformation.zipCode);
        setValue('city', vatInformation.city);
    }

    const selectAddress = (address: DawaAutocompleteModel) => {
        setValue('address', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('zipCode', address.adresse.postnr);
        setValue('city', address.adresse.postnrnavn);
        clear();
    }
    
    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Select placeholder={t('customerType')} error={errors.customerType} {...register('customerType')} >
                <option value={'Customer'}>{t('customer')}</option>
                <option value={'Utility'}>{t('utility')}</option>
            </Select>

            <ControlledInput placeholder={t('name')} error={errors.name} {...register('name', {required: true})} />

            <Flex $spaceBetween $alignCenter>
                <StyledLabel children={t('vatNumber')} />
                <Dropdown content={
                    <>
                        {results?.map((result, index) => (
                            <DropdownItem key={result.vatNumber} onClick={() => selectVatInformation(result)}>
                                <div>{result.name}</div>
                            </DropdownItem>
                        ))}
                    </>
                }>
                    <ControllerInput name={"vatNumber"} control={control} type={"number"} rules={{minLength: {
                            message: t('validation.minimum8'),
                            value: 8
                        }}} onChange={(e) => search(e.currentTarget.value)} />
                </Dropdown>
            </Flex>

            <ControlledInput placeholder={t('phone')} error={errors.phone} {...register('phone', {required: true, minLength: {
                message: t('validation.minimum8'),
                value: 8
            }})} />
            
            <ControlledInput placeholder={t('email')} error={errors.email} {...register('email', {required: true})} />
            
            <Flex $spaceBetween $alignCenter>
                <StyledLabel children={t('address')} />
                <Dropdown content={
                    <>
                        {addressResults?.map((result, index) => (
                            <DropdownItem key={result.adresse.id} onClick={() => selectAddress(result)}>
                                <div>{result.tekst}</div>
                            </DropdownItem>
                        ))}
                    </>
                }>
                    <ControlledInput error={errors.address} {...register('address', {required: true})} onChange={(e) => searchAddresses(e.currentTarget.value)}  />
                </Dropdown>
            </Flex>
            
            <ControlledInput type={"number"} placeholder={t('zipCode')} error={errors.zipCode} {...register('zipCode', {required: true, minLength: {
                message: t('validation.minimum4'),
                value: 4
            }})} />
            
            <ControlledInput placeholder={t('city')} error={errors.city} {...register('city', {required: true})} />

            <Select placeholder={t('customerUser')} error={errors.customerUserId} {...register('customerUserId')} >
                <option value={''}>{t('select')}</option>
                <option value={-1}>{t('createNewUser')}</option>
                {users?.items.filter(x => x.accessLevel <= AccessLevel.Customer)?.map(user => (
                    <option key={user.id} value={user.id}>{user.name}</option>
                ))}
            </Select>
            
            <Flex $justifyEnd>
                <Button loading={isLoading || isCreatingUser} type={"submit"}>
                    {t('save')}
                </Button>
            </Flex>
        </StyledForm>
    )

}

export default CustomerForm