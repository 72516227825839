
import StyledInput from "./BaseComponents/StyledInput";
import Flex from "./BaseComponents/Flex";
import StyledLabel from "./BaseComponents/Label";
import React from "react";

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> {
    secondaryLabel?: string;
    hasError?: boolean;
    placeholder?: string | null | undefined;
}
const Input = React.forwardRef((props: Props, ref: any) => {

    return (
        <Flex $spaceBetween>
            <Flex $column $justifyCenter>
                <StyledLabel htmlFor={props.name} children={props.placeholder} />
                {props.secondaryLabel && <StyledLabel style={{fontSize: '0.8rem', color: '#E91D25'}} children={props.secondaryLabel} />}
            </Flex>
            <StyledInput
                $error={props.hasError}
                ref={ref}
                {...props}
                placeholder={props.placeholder ?? ''}
            />
        </Flex>
    )
})

Input.defaultProps = {
    type: 'text'
}

export default Input