import {FieldError} from "react-hook-form";
import Input from "./Input";
import React, {useMemo} from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    placeholder?: string | undefined | any;
    defaultValue?: string;
    error?: FieldError | undefined;
}

const ControlledInput = React.forwardRef((props: Props, ref) =>  {
    
    const errorMessage = useMemo(() => {
        if (props.error?.message) {
            return props.error?.message;
        }
    }, [props.error])
    
    return (
        <Input hasError={!!props.error} secondaryLabel={errorMessage} ref={ref} {...props} />
    )
})


export default ControlledInput