import styled, { css } from "styled-components";

export default styled.textarea<{$error?: boolean}>`
    all: unset;
    border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.colors.primaryText};
    margin-top: ${props => props.theme.spacing(1)};
    padding: ${props => props.theme.spacing(1)};
    line-height: 1.5;
    
  
    ${props => props.$error && css `
        border-color: ${props => props.theme.colors.danger};
    `}
`