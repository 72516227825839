import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {BlockServiceDto, MutateBlockServiceDto} from "./BlockServiceDto";
import {QueryParams} from "../../../Apis/Models/QueryParams";
import {PaginatedList} from "../../../Apis/Models/PaginatedList";
import { saveAs } from 'file-saver';

export const BLOCK_SERVICES_CACHE_KEY = 'BLOCK_SERVICES_CACHE_KEY'
export const useBlockServices = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<BlockServiceDto>, unknown>([BLOCK_SERVICES_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<BlockServiceDto>>({
            url: `/blocks/services`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useCustomerViewBlockServices = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<BlockServiceDto>, unknown>([BLOCK_SERVICES_CACHE_KEY, 'CUSTOMER_VIEW', queryable], async () => {
        return await request<PaginatedList<BlockServiceDto>>({
            url: `/customerView/blocks/services`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useBlockService = (id: number) => {
    return useQuery<BlockServiceDto, unknown>([BLOCK_SERVICES_CACHE_KEY, id], async () => {
        return await request<BlockServiceDto>({
            url: `/blocks/services/${id}`,
            method: 'GET',
        })});
}

export const useCustomerViewBlockService = (id: number) => {
    return useQuery<BlockServiceDto, unknown>([BLOCK_SERVICES_CACHE_KEY, 'CUSTOMER_VIEW', id], async () => {
        return await request<BlockServiceDto>({
            url: `/customerView/blocks/services/${id}`,
            method: 'GET',
        })});
}

export const useMutateBlockService = () => {
    const queryClient = useQueryClient();

    return useMutation(async (blockService: MutateBlockServiceDto) => {
        if (!blockService.id) {
            return await request({
                url: `/blocks/services`,
                method: 'POST',
                data: blockService
            })
        } else {
            return await request({
                url: `/blocks/services/${blockService.id}`,
                method: 'PUT',
                data: blockService
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_SERVICES_CACHE_KEY])
        }
    })
}

export const useDeleteBlockService = () => {
    const queryClient = useQueryClient();

    return useMutation(async (blockService: BlockServiceDto) => {
        return await request({
            url: `/blocks/services/${blockService.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_SERVICES_CACHE_KEY])
        }
    })
}

export const downloadBlockServiceReport = async (id: number, fileName: string): Promise<any> => {

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/blocks/services/${id}/download/report`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, fileName);
}


export const customerViewDownloadBlockServiceReport = async (id: number, fileName: string): Promise<any> => {

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/customerview/blocks/services/${id}/download/report`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, fileName);
}
