import { H1 } from "Components/BaseComponents/Heading";
import React from "react";
import { useTranslation } from "react-i18next";
import {UserList, UsersApi} from "../../Features/Users";
import Flex from "../../Components/BaseComponents/Flex";
import Button from "../../Components/Button";
import StyledInput from "../../Components/BaseComponents/StyledInput";
import { useNavigate } from "react-router-dom";
import Gap from "Components/BaseComponents/Gap";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";

const Index = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [ filter, setFilter ] = useQueryParams({page: 0, pageSize: 25});

    const { data, isLoading } = UsersApi.useUsers(filter);
    
    return (
        <>
            <Gap $vertical>
                
            <Flex $spaceBetween>
                <H1>{t('users')}</H1>
                <Gap >
                    <Button onClick={() => navigate("create")} color={"secondary"}>{t('createUser')}</Button>
                </Gap>
            </Flex>

                <StyledInput $block placeholder={t('search')} onChange={e => setFilter({search: e.currentTarget.value})} />

                <UserList data={data?.items} isLoading={isLoading}  />

                <Pagination paginatedList={data} onPageChange={newPage => setFilter({page: newPage})} />
            </Gap>
        </>
    )
}

export default Index