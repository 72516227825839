import {SchemaDefinitionDto, SchemaQuestionDefinitionDto} from "../Services/SchemaDefinitionDto";
import React from "react";
import Flex from "Components/BaseComponents/Flex";
import {SchemaDto} from "../Services/SchemaDto";
import styled from "styled-components";

interface Props {
    schema?: Partial<SchemaDto>;
    schemaDefinition: SchemaDefinitionDto;
}
const SchemaRender = (props: Props) => {

    const { schema, schemaDefinition } = props;
    
    const getAnswer = (question: SchemaQuestionDefinitionDto) => {
        let answer = schema?.answers?.find(x => x.order === question.order);
        
        let answerValues: string[][] = JSON.parse(answer!.value);
        
        return answerValues.map((answerValue, index) => (
            <Flex $spaceBetween $column key={index}>
                {question.fields.map(field => (
                    <Flex $spaceBetween $column={field.type === "TextArea"} style={{paddingTop: 10}}>
                        <Label children={field.text}/>

                        <div style={{paddingTop: field.type === "TextArea" ? 10 : 0}}>
                            {answerValue[field.order - 1]}
                        </div>
                    </Flex>
                ))}
            </Flex>
        ))
    }
    
    return (
        <>
        {schemaDefinition.schemeQuestions.map((question) => (
                <React.Fragment key={question.order}>
                    {getAnswer(question)}
                </React.Fragment>
            ))}
        </>
    )
}

const Label = styled.div`
  font-weight: bold;
`

export default SchemaRender