import styled from "styled-components";
import {useRef, useState} from "react";
import React from "react";

interface Props {
    children: any;
    content: any;
}
const Dropdown = (props: Props) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    
    const [visible, setVisible] = useState<boolean>(false);
    
    const show = () => {
        setVisible(true);
    }

    const close = () => {
        setVisible(false);
    }

    return (
        <Container>
            <div ref={containerRef}>
                {React.cloneElement(props.children, {onBlur: close, onFocus: show, onClick: show})}
            </div>
            <Content ref={contentRef} style={{opacity: visible ? 1: 0, top: `${containerRef.current?.getBoundingClientRect().height}px`}}>
                {props.content}
            </Content>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
`

const Content = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.colors.border};
    background-color: white;
    overflow: scroll;
    height: fit-content;
    max-height: 300px;
    transition: height 0.15s ease-in-out;
`

export const DropdownItem = styled.div`
    padding: ${props => props.theme.spacing(1)};
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
  
  &:hover {
    background-color: ${props => props.theme.colors.light};;  
  }
`

export default Dropdown