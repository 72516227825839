import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {BlockSubscriptionDto, MutateBlockSubscriptionDto} from "./BlockSubscriptionDto";
import {QueryParams} from "../../../Apis/Models/QueryParams";
import {PaginatedList} from "../../../Apis/Models/PaginatedList";

export const BLOCK_SUBSCRIPTION_CACHE_KEY = 'BLOCK_SUBSCRIPTION_CACHE_KEY'
export const useBlockSubscriptions = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<BlockSubscriptionDto>, unknown>([BLOCK_SUBSCRIPTION_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<BlockSubscriptionDto>>({
            url: `/blocks/subscriptions`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useBlockSubscription = (id: number) => {
    return useQuery<BlockSubscriptionDto, unknown>([BLOCK_SUBSCRIPTION_CACHE_KEY, id], async () => {
        return await request<BlockSubscriptionDto>({
            url: `/blocks/subscriptions/${id}`,
            method: 'GET',
        })});
}

export const useMutateBlockSubscription = () => {
    const queryClient = useQueryClient();

    return useMutation(async (blockSubscription: MutateBlockSubscriptionDto) => {
        if (!blockSubscription.id) {
            return await request({
                url: `/blocks/subscriptions`,
                method: 'POST',
                data: blockSubscription
            })
        } else {
            return await request({
                url: `/blocks/subscriptions/${blockSubscription.id}`,
                method: 'PUT',
                data: blockSubscription
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_SUBSCRIPTION_CACHE_KEY])
        }
    })
}

export const useDeleteBlockSubscription = () => {
    const queryClient = useQueryClient();

    return useMutation(async (blockSubscription: BlockSubscriptionDto) => {
        return await request({
            url: `/blocks/subscriptions/${blockSubscription.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_SUBSCRIPTION_CACHE_KEY])
        }
    })
}