import Flex from "./BaseComponents/Flex";
import StyledLabel from "./BaseComponents/Label";
import React from "react";
import StyledTextArea from "./BaseComponents/StyledTextArea";

interface Props extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'placeholder'> {
    secondaryLabel?: string;
    hasError?: boolean;
    placeholder?: string | null | undefined;
    rows?: number;
}
const Textarea = React.forwardRef((props: Props, ref: any) => {

    return (
        <Flex $column>
            <Flex $spaceBetween $alignCenter>
                <StyledLabel htmlFor={props.name} children={props.placeholder} />
                {props.secondaryLabel && <StyledLabel style={{fontSize: '0.8rem', color: '#E91D25'}} children={props.secondaryLabel} />}
            </Flex>
            <StyledTextArea
                rows={props.rows ?? 2}
                $error={props.hasError}
                ref={ref}
                {...props}
                placeholder={props.placeholder ?? ''}
            />
        </Flex>
    )
})

export default Textarea