import { Navigate, useLocation } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";
import { ReactElement } from "react";

interface IProps {
    children: ReactElement;
}
const RequireAuthentication = ({children}: IProps) => {
    let location = useLocation();

    const user = useAuthProvider();

    if (!user?.firebaseUser && !location.pathname.includes("/signin")) {
        return <Navigate to={"/signin"}/>
    }

    return children;
}

export default RequireAuthentication;
