import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import UserForm from "Features/Users/Components/UserForm";

const Create = () => {

    const { t } = useTranslation();
    
    const navigate = useNavigate();

    return (
        <>
            <H1 style={{paddingBottom: 10}}>{t('createUser')}</H1>

            <UserForm onSubmit={() => navigate(-1)}  />
        </>
    )

}

export default Create