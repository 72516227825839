import styled, {css} from "styled-components";

export default styled.div<{$column?: boolean; $justifyCenter?: boolean; $justifyEnd?: boolean, $spaceBetween?: boolean; $alignCenter?: boolean}> `
    display: flex;
  
    ${props => props.$column && css`
        flex-direction: column;
    `}

    ${props => props.$justifyCenter && css`
        justify-content: center;
    `}

    ${props => props.$justifyEnd && css`
        justify-content: flex-end;
    `}

    ${props => props.$spaceBetween && css`
        justify-content: space-between;
    `}

    ${props => props.$alignCenter && css`
        align-items: center;
    `}
`
    