import List from "Components/List";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FaPen, FaTrash} from "react-icons/fa6";
import Clickable from "Components/BaseComponents/Clickable";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import Gap from "../../../Components/BaseComponents/Gap";
import useToasts from "../../../Hooks/UseToasts";
import {BlockSubscriptionDto} from "../Services/BlockSubscriptionDto";
import {useDeleteBlockSubscription} from "../Services/BlockSubscriptionApi";
import {AccessLevel} from "../../Users/Services/UserDto";
import AccessLevelGuard from "../../../Components/AccessLevelGuard";
import {BlockSubscriptionColor} from "./BaseComponents/BlockSubscriptionColor";

interface Props {
    data?: BlockSubscriptionDto[];
    isLoading: boolean;
}
const BlockSubscriptionsList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast } = useToasts();
    
    const { mutateAsync: deleteBlockSubscriptionMutation, isLoading: isDeleting } = useDeleteBlockSubscription();
    
    const deleteBlockSubscription = async (blockSubscription: BlockSubscriptionDto) => {
        await deleteBlockSubscriptionMutation(blockSubscription);
        
        showSuccessToast(t('blockSubscriptionDeleted'));
    }

    return (
        <List<BlockSubscriptionDto> data={props.data} loading={props.isLoading} columns={[
            {
                title: t('color'),
                customRender: (record) => (
                    <BlockSubscriptionColor style={{backgroundColor: record.color}} />
                ),
                size: 'min-content'
            },
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('intervalInMonth'),
                textRender: (record) => record.interval
            },
            {
                title: t('scheme'),
                textRender: (record) => t(`schemeName.${record.schemeName}`)
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                        <Gap>
                            <AccessLevelGuard accessLevel={AccessLevel.Admin}>
                                <Clickable onClick={() => navigate(`/blocks/subscriptions/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                                <ConfirmationDialog loading={isDeleting} onConfirm={() => deleteBlockSubscription(record)} buttonType={'danger'} title={t('areYouSure')}>
                                    <Clickable><FaTrash />{t('delete')}</Clickable>
                                </ConfirmationDialog>
                            </AccessLevelGuard>
                        </Gap>
                    ) 
                
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default BlockSubscriptionsList