import List from "Components/List";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FaPen, FaTrash} from "react-icons/fa6";
import Clickable from "Components/BaseComponents/Clickable";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import Gap from "../../../Components/BaseComponents/Gap";
import useToasts from "../../../Hooks/UseToasts";
import {CameraLocationDto} from "../Services/CameraLocationDto";
import {useDeleteCameraLocation} from "../Services/CameraLocationApi";

interface Props {
    data?: CameraLocationDto[];
    isLoading: boolean;
}
const CameraLocationsList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast } = useToasts();
    
    const { mutateAsync: deleteCameraLocationMutation, isLoading: isDeleting } = useDeleteCameraLocation();
    
    const deleteBlockType = async (cameraLocation: CameraLocationDto) => {
        await deleteCameraLocationMutation(cameraLocation);
        
        showSuccessToast(t('cameraLocationDeleted'));
    }

    return (
        <List<CameraLocationDto> data={props.data} loading={props.isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                        <Gap>
                            <Clickable onClick={() => navigate(`/cameras/locations/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                            <ConfirmationDialog loading={isDeleting} onConfirm={() => deleteBlockType(record)} buttonType={'danger'} title={t('areYouSure')}>
                                <Clickable><FaTrash />{t('delete')}</Clickable>
                            </ConfirmationDialog>
                        </Gap>
                    ) 
                
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default CameraLocationsList