import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ContentLoader from "Components/ContentLoader";
import CameraLocationForm from "Features/Cameras/Components/CameraLocationForm";
import {useCameraLocation} from "../../../Features/Cameras/Services/CameraLocationApi";

const Edit = () => {

    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data, isLoading } = useCameraLocation(parseInt(id!))
    
    const navigate = useNavigate();
    
    return (
        <ContentLoader shown={isLoading}>
            <H1 style={{paddingBottom: 10}}>{t('editCameraLocation')}</H1>
            
            <CameraLocationForm entity={data} onSubmit={() => navigate(-1)}  />
        </ContentLoader>
    )

}

export default Edit