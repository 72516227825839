import Flex from "Components/BaseComponents/Flex";
import ControlledInput from "Components/ControlledInput";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import {Controller, useForm } from "react-hook-form";
import useToasts from "Hooks/UseToasts";
import React, {useEffect} from "react";
import {BlockSubscriptionDto, MutateBlockSubscriptionDto} from "../Services/BlockSubscriptionDto";
import {useMutateBlockSubscription} from "../Services/BlockSubscriptionApi";
import ColorPicker from "../../../Components/ColorPicker";
import { StyledForm } from "Components/BaseComponents/StyledForm";
import Select from "../../../Components/Select";

interface Props {
    entity?: BlockSubscriptionDto;
    onSubmit?: () => void;
}
const BlockSubscriptionForm = (props: Props) => {

    const { t } = useTranslation();    
    
    const { showSuccessToast } = useToasts();
    
    const { control, register, handleSubmit, reset, formState: { errors } } = useForm<MutateBlockSubscriptionDto>();
    
    const { mutateAsync, isLoading } = useMutateBlockSubscription();
    
    useEffect(() => {
        if (!!props.entity) {
            reset(props.entity)
        }
    }, [reset,  props.entity]);
    
    const onSubmit = async (blockSubscription: MutateBlockSubscriptionDto) => {
        await mutateAsync(blockSubscription);
        
        showSuccessToast(t('blockSubscriptionSaved'));
        
        props.onSubmit?.();
    }
    
    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput placeholder={t('name')} error={errors.name} {...register('name', {required: true})} />
            
            <Controller control={control} name={"color"} render={({field}) => (
                <ColorPicker label={t('color')} name={field.name} onChange={field.onChange} value={field.value} />
            )} />

            <ControlledInput placeholder={t('intervalInMonths')} error={errors.interval} {...register('interval')} type={"number"} />

            <Select placeholder={t('scheme')} error={errors.schemeName} {...register('schemeName')} >
                <option value={''}>{t('select')}</option>
                <option value={'Block'}>{t('schemeName.Block')}</option>
                <option value={'HighTideBlock'}>{t('schemeName.HighTideBlock')}</option>
            </Select>

            <Flex $justifyEnd>
                <Button loading={isLoading} type={"submit"}>
                    {t('save')}
                </Button>
            </Flex>
        </StyledForm>
    )

}

export default BlockSubscriptionForm