import { H1 } from "Components/BaseComponents/Heading";
import React from "react";
import { useTranslation } from "react-i18next";
import Menu from "Layouts/Menu";
import Button from "Components/Button";
import Flex from "Components/BaseComponents/Flex";
import {useNavigate} from "react-router-dom";
import StyledInput from "Components/BaseComponents/StyledInput";
import Gap from "Components/BaseComponents/Gap";
import {BlockApi, BlocksList} from "Features/Blocks";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";

const Index = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ filter, setFilter ] = useQueryParams({page: 0, pageSize: 25});

    const { data, isLoading } = BlockApi.useBlocks(filter, true);
    
    return (
        <>
            <Flex $spaceBetween>
                <H1>{t('blocks')}</H1>
                <div>
                    <Button onClick={() => navigate("create")} color={"secondary"}>{t('createBlock')}</Button>
                </div>
            </Flex>
            
            <Gap $vertical>
                <Menu menuItems={[
                    {
                        label: t('menu.blocks'),
                        to: "/blocks",
                        exact: true
                    },
                    {
                        label: t('menu.blockServices'),
                        to: "/blocks/services",
                    },
                    {
                        label: t('menu.blockTypes'),
                        to: "/blocks/types",
                    },
                    {
                        label: t('menu.blockSubscriptions'),
                        to: "/blocks/subscriptions"
                    }
                ]}  />
    
                <StyledInput $block placeholder={t('search')} defaultValue={filter?.search?.toString() ?? ''} onChange={e => setFilter({search: e.currentTarget.value})} />
                
                <BlocksList data={data?.items} isLoading={isLoading}  />

                <Pagination paginatedList={data} onPageChange={newPage => setFilter({page: newPage})} />
            </Gap>
        </>
    )
}


export default Index