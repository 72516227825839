import _ from "lodash";
import {useCallback, useState} from "react";
import {useVatInformation} from "../Apis/VatInformationApi";


export const useVatInformationSearch = () => {

    const [vatInformationQuery, setVatInformationQuery] = useState<string>('');

    const { data: results, isLoading: isLoadingVatResults  } = useVatInformation(vatInformationQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(_.debounce(async (query: string) => {
        setVatInformationQuery(query)
    }, 100), [])
    
    return { search, results, isLoadingVatResults }
}