import {useEffect, useState} from "react";
import {QueryParams} from "Apis/Models/QueryParams";
import {useLocation, useNavigate} from "react-router-dom";
import {isNumber} from "../Utils/NumberUtils";
import _ from "lodash";

const useQueryParams = (defaultParams?: QueryParams): [QueryParams | undefined, (params: QueryParams, resetParams?: boolean ) => void] => {

    const navigate = useNavigate();
    const location = useLocation();

    const [ internalFilter, setInternalFilter ] = useState<QueryParams>();


    useEffect(() => {
        const currentParams = new URLSearchParams(location.search);

        const newParams = defaultParams ?? {};

        currentParams.forEach((value, key) => {
            if (value !== undefined && value !== null && value !== '') {
                newParams[key] = isNumber(value) ? parseInt(value) : value;
            }
        });
        
        setInternalFilter(newParams)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setFilter = _.debounce((newValues: QueryParams, resetParams: boolean = false) => {

        if (resetParams) {
            newValues = {...newValues, ...defaultParams}
        }

        setInternalFilter(current => { return {...current, ...newValues}})

        const params = new URLSearchParams();

        Object.keys(newValues).forEach(key => {
            if (newValues[key] !== undefined && newValues[key] !== null) {
                params.append(key, newValues[key]!.toString());
            }
        });

        navigate({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });
    }, 300)

    return [ internalFilter, setFilter ]

}

export default useQueryParams