import ResetPasswordForm from "Features/Authentication/Components/ResetPasswordForm";
import Flex from "../Components/BaseComponents/Flex";

const ResetPassword = () => {
    
    return (
        <Flex style={{height: '100vh'}} $justifyCenter $alignCenter>
            <div style={{width: 550}}>
                <ResetPasswordForm />
            </div>
        </Flex>
    )
}

export default ResetPassword
