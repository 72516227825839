import {H1} from "Components/BaseComponents/Heading";
import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import BlockServiceForm from "../../../Features/Blocks/Components/BlockServiceForm";
import {useBlock} from "../../../Features/Blocks/Services/BlockApi";
import ContentLoader from "../../../Components/ContentLoader";

const Create = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams();

    const { data, isLoading } = useBlock(parseInt(id!))
    
    return (
        <ContentLoader shown={isLoading}>
            <H1 style={{paddingBottom: 10}}>{t('createBlockService')} - {data?.serialNumber}</H1>

            <BlockServiceForm entity={{
                blockId: parseInt(id!),
            }} onSubmit={() => navigate(-1)}  />
        </ContentLoader>
    )

}

export default Create