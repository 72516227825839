import styled, {css} from "styled-components";

export default styled.button<{$color: 'secondary' | 'default' | 'danger'}>`
  all: unset;
  cursor: pointer;
  
  color: ${props => props.theme.colors.primaryText};
  border-width: ${props => props.theme.dimensions.borderWidth};
  border-style: solid;
  line-height: 1.5;
  
  transition: ${props => props.theme.transition.default};

  padding: ${props => props.theme.spacing(1)}  ${props => props.theme.spacing(6)};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  ${props => props.$color === 'default' && css `
    background-color: ${props => props.theme.colors.success};
    border-color: transparent;
    color: white;

    > div > svg {
      fill: white;
    }
  `}
  
  ${props => props.$color === 'secondary' && css `
      border-color: ${props => props.theme.colors.primaryText};
      color: ${props => props.theme.colors.primaryText};

    &:hover > div > svg{
      fill: white
    }
  `}

  ${props => props.$color === 'danger' && css `
    background-color: ${props => props.theme.colors.danger};
    border-color: transparent;
    color: white;

    > div > svg{
      fill: white
    }
  `}
  
  &:hover {
    background-color: ${props => props.theme.colors.primaryText};
    color: white;
  }
`;