import _ from "lodash";
import {useCallback, useState} from "react";
import {useAddressSearchQuery} from "../Apis/AddressApi";

export const useAddressSearch = () => {

    const [addressQuery, setAddressQuery] = useState<string>('');

    const { data: addressResults, isLoading: isLoadingAddressResults  } = useAddressSearchQuery(addressQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchAddresses = useCallback(_.debounce(async (query: string) => {
        setAddressQuery(query)
    }, 100), [])
    
    const clear = () => {
        setAddressQuery('');
    }
    
    return { searchAddresses, addressResults, isLoadingAddressResults, clear }
}