import {AccessLevel} from "../Features/Users/Services/UserDto";
import useHasAccess from "../Features/Users/Hooks/useHasAccess";

interface Props {
    accessLevel: AccessLevel;
    children: any;
}
const AccessLevelGuard = (props: Props) => {

    const hasAccess = useHasAccess(props.accessLevel);
    
    if (!hasAccess) {
        return <></>
    }
    
    return props.children;
}

export default AccessLevelGuard