import { useForm } from "react-hook-form";
import Button from "Components/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { auth } from "FirebaseSetup/auth";
import useToasts from "../../../Hooks/UseToasts";
import {useNavigate} from "react-router-dom";
import ControlledInput from "../../../Components/ControlledInput";
import Flex from "../../../Components/BaseComponents/Flex";
import {H1} from "../../../Components/BaseComponents/Heading";
import Gap from "Components/BaseComponents/Gap";
import Box from "Components/BaseComponents/Box";

interface ResetPasswordFormFields {
    password: string;
}

interface IProps {
    oobCode: string
}

const NewPasswordForm = (props: IProps) => {
    
    const { oobCode } = props;
    
    const { t } = useTranslation();

    const { showSuccessToast, showErrorToast } = useToasts();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    
    const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordFormFields>();
    
    const resetPassword = async (data: ResetPasswordFormFields) => {
        setLoading(true);
    
        try {
            await auth.confirmPasswordReset(oobCode, data.password);

            showSuccessToast(t('passwordResetSuccess'));

            navigate('/signin');
        }
        catch (e) {
            showErrorToast(t('anErrorOccurred'));
        }
        finally {
            setLoading(false);
        }
    }
    
    return (
        <form onSubmit={handleSubmit(resetPassword)}>
            <Box>
                <Gap $vertical $block>
                    <Flex $justifyCenter>
                        <H1>{t('resetPassword')}</H1>
                    </Flex>

                    <ControlledInput placeholder={t('newPassword')} type={"password"} error={errors.password} {...register('password', {
                            required: true,
                            minLength: {
                                value: 6,
                                message: t('validation.minimum6')}
                        }
                    )} />

                    <Flex $justifyEnd>
                        <Button loading={loading} type={"submit"} children={t('resetpassword')} />
                    </Flex>
                </Gap>
            </Box>
        </form>
    )
}

export default NewPasswordForm
