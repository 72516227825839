import List from "Components/List";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FaPen} from "react-icons/fa6";
import Clickable from "Components/BaseComponents/Clickable";
import Gap from "../../../Components/BaseComponents/Gap";
import {CameraDto} from "../Services/CameraDto";
import {useCameraLocations} from "../Services/CameraLocationApi";
import {useUsers} from "../../Users/Services/UsersApi";
import moment from "moment";

interface Props {
    data?: CameraDto[];
    isLoading: boolean;
    setSorting: (columnKey?: string) => void
}
const CamerasList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: cameraLocations } = useCameraLocations();
    const { data: employees } = useUsers();

    return (
        <List<CameraDto> data={props.data} onTitleClick={props.setSorting} loading={props.isLoading} columns={[
            {
                title: t('serialNumber'),
                textRender: (record) => record.serialNumber,
                columnKey: "serialNumber"
            },
            {
                title: t('installationDate'),
                textRender: (record) => record.installationDate ? moment(record.installationDate).format('DD / MM / YYYY') : '',
                columnKey: "installationDate"
            },
            {
                title: t('removalDate'),
                textRender: (record) => record.removalDate ? moment(record.removalDate).format('DD / MM / YYYY') : ''
            },
            {
                title: t('employee'),
                textRender: (record) => employees?.items.find(x => x.id === record.employeeId)?.name ?? ""
            },
            {
                title: t('location'),
                textRender: (record) => cameraLocations?.items.find(x => x.id === record.locationId)?.name ?? ""
            },
            {
                title: t('address'),
                textRender: (record) => `${record.address} ${record.city} ${record.zipCode}`
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                        <Gap>
                            <Clickable onClick={() => navigate(`/cameras/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                        </Gap>
                    ) 
                
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default CamerasList