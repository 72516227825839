import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ContentLoader from "Components/ContentLoader";
import {useBlock} from "../../Features/Blocks/Services/BlockApi";
import BlockForm from "../../Features/Blocks/Components/BlockForm";

const Edit = () => {

    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data, isLoading } = useBlock(parseInt(id!))
    
    const navigate = useNavigate();
    
    return (
        <ContentLoader shown={isLoading}>
            <H1 style={{paddingBottom: 10}}>{t('editBlock')}</H1>

            <BlockForm entity={data} onSubmit={() => navigate(-1)} />
        </ContentLoader>
    )

}

export default Edit