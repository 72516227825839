import Flex from "Components/BaseComponents/Flex";
import ControlledInput from "Components/ControlledInput";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import {useForm } from "react-hook-form";
import useToasts from "Hooks/UseToasts";
import React, {useEffect} from "react";
import { useMutateContact } from "../Services/ContactApi";
import {ContactDto, MutateContactDto } from "../Services/ContactDto";
import Select from "../../../Components/Select";
import { useCustomers } from "../Services/CustomerApi";
import {StyledForm} from "../../../Components/BaseComponents/StyledForm";
import {AccessLevel} from "../../Users/Services/UserDto";
import {useMutateUser, useUsers} from "../../Users/Services/UsersApi";

interface Props {
    contact?: ContactDto;
    onSubmit?: () => void;
}
const CustomerForm = (props: Props) => {

    const { t } = useTranslation();    
    
    const { showSuccessToast } = useToasts();
    const { data: customers } = useCustomers();
    const { data: users } = useUsers();

    const {  register, handleSubmit, reset, formState: { errors } } = useForm<MutateContactDto>();
    
    const { mutateAsync, isLoading } = useMutateContact();
    const { mutateAsync: createUser, isLoading: isCreatingUser } = useMutateUser();

    useEffect(() => {
        if (!!props.contact) {
            reset(props.contact)
        }
    }, [reset,  props.contact]);
    
    const onSubmit = async (contact: MutateContactDto) => {
        if (contact.contactUserId?.toString() === "-1") {
            let user = await createUser({
                name: contact.name,
                email: contact.email,
                accessLevel: AccessLevel.Customer,
            });

            contact.contactUserId = user.id
        }
        
        await mutateAsync(contact);
        
        showSuccessToast(t('contactSaved'));
        
        props.onSubmit?.();
    }
    
    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Select placeholder={t('customer')} error={errors.customerId} {...register('customerId', { required: true})} >
                <option value={''}>{t('select')}</option>
                {customers?.items.map(customer => (
                    <option key={customer.id} value={customer.id}>{customer.name}</option>
                ))}
            </Select>
            
            <ControlledInput placeholder={t('name')} error={errors.name} {...register('name', {required: true})} />
            
            <ControlledInput placeholder={t('phone')} error={errors.phone} {...register('phone', {required: true, minLength: {
                message: t('validation.minimum8'),
                value: 8
            }})} />
            <ControlledInput placeholder={t('email')} error={errors.email} {...register('email', {required: true})} />

            <Select placeholder={t('customerUser')} error={errors.contactUserId} {...register('contactUserId')} >
                <option value={''}>{t('select')}</option>
                <option value={-1}>{t('createNewUser')}</option>
                {users?.items.filter(x => x.accessLevel === AccessLevel.Customer)?.map(user => (
                    <option key={user.id} value={user.id}>{user.name}</option>
                ))}
            </Select>
            
            <Flex $justifyEnd>
                <Button loading={isLoading || isCreatingUser} type={"submit"}>
                    {t('save')}
                </Button>
            </Flex>
        </StyledForm>
    )

}

export default CustomerForm