import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ContentLoader from "../../Components/ContentLoader";
import {useUser} from "../../Features/Users/Services/UsersApi";
import UserForm from "Features/Users/Components/UserForm";

const Edit = () => {

    const { t } = useTranslation();

    const { id } = useParams();

    const { data, isLoading } = useUser(parseInt(id!))

    const navigate = useNavigate();

    return (
        <ContentLoader shown={isLoading}>
            <H1 style={{paddingBottom: 10}}>{t('editUser')}</H1>

            <UserForm user={data} onSubmit={() => navigate(-1)}  />
        </ContentLoader>
    )

}

export default Edit