import React from "react";
import {useUpcommingServiceBlocks} from "../../Features/Blocks/Services/BlockApi";
import {useBlockSubscriptions} from "../../Features/Blocks/Services/BlockSubscriptionApi";
import Flex from "Components/BaseComponents/Flex";
import {useTranslation} from "react-i18next";
import {H1} from "../../Components/BaseComponents/Heading";
import Menu from "../../Layouts/Menu";
import Gap from "Components/BaseComponents/Gap";
import StyledInput from "../../Components/BaseComponents/StyledInput";
import useQueryParams from "../../Hooks/UseQueryParams";
import styled from "styled-components";
import {BlockSubscriptionColor} from "../../Features/Blocks/Components/BaseComponents/BlockSubscriptionColor";
import MarkerMap from "../../Components/MarkerMap";
import {BlockDto} from "../../Features/Blocks/Services/BlockDto";
import { useCustomers } from "Features/Customers/Services/CustomerApi";
import { useContacts } from "Features/Customers/Services/ContactApi";
import ContentLoader from "../../Components/ContentLoader";
import {getBlockPopupContent} from "../../Utils/BlockUtils";
import DateTimePicker from "../../Components/DateTimePicker";
import moment from "moment";

const BlockServiceMap = () => {

    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams({active: true, until: moment().add(2, 'month').format('yyyy-MM-DD')});

    const { data } = useUpcommingServiceBlocks(filter);
    const { data: customers, isLoading: fetchingCustomers } = useCustomers();
    const { data: contacts, isLoading: fetchingContacts } = useContacts();
    const { data: subscriptions, isLoading: fetchingSubscriptions } = useBlockSubscriptions();
    
    const getMarkerColor = (block: BlockDto) => {
        return subscriptions?.items.find(x => x.id === block.blockSubscriptionId)?.color ?? 'red'
    }

    const getMarkerFill = (block: BlockDto) => {
        return block.importantNotes ? 'red' : 'white';
    }

    const getContentString = (block: BlockDto) => {
        return getBlockPopupContent(block,  customers!.items, contacts!.items, t)
    }
    
    return (
        <>
        <Flex $spaceBetween>
            <H1>{t('map')}</H1>
        </Flex>

        <Gap $vertical>
            <Menu menuItems={[
                {
                    label: t('menu.blocks'),
                    to: "/map/blocks"
                },
                {
                    label: t('menu.serviceMap'),
                    to: "/map/serviceMap"
                },
                {
                    label: t('menu.cameras'),
                    to: "/map/cameras"
                }
            ]}  />

            <Gap>
                <StyledInput $block placeholder={t('search')} defaultValue={filter?.search?.toString() ?? ''} onChange={e => setFilter({search: e.currentTarget.value})} />
                <DateTimePicker value={filter?.until?.toString() ?? ''} onChange={e => setFilter({until: moment(e).format('yyyy-MM-DD')})} name={"until"} label={""} />
            </Gap>

                <ContentLoader shown={fetchingCustomers || fetchingContacts || fetchingSubscriptions}>
                    <MarkerMap
                        getMarkerTitle={block => block.serialNumber}
                        getInfoWindowContent={getContentString}
                        getMarkerColor={getMarkerColor}
                        getMarkerFill={getMarkerFill}
                        data={data?.items}
                    />
                </ContentLoader>

                <SubscriptionsContainer>
                    {subscriptions?.items.map(subscription => (
                        <div>
                            <BlockSubscriptionColor style={{backgroundColor: subscription.color}} /> {subscription.name}
                        </div>
                    ))}
                </SubscriptionsContainer>
                <SubscriptionsContainer>
                    <BlockSubscriptionColor style={{backgroundColor: 'red'}} /> {t('redCenterDescription')}
                </SubscriptionsContainer>
            </Gap>
        </>
    )
}

const SubscriptionsContainer = styled.div`
    display: flex;
    gap: ${props => props.theme.spacing(2)};
  
  > div {
    gap: ${props => props.theme.spacing(1)};
    display: flex;
    align-items: center;
  }
`

export default BlockServiceMap