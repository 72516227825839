import Flex from "./BaseComponents/Flex";
import StyledLabel from "./BaseComponents/Label";
import React, {useMemo} from "react";
import {Controller, FieldError} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ReactSelect, { StylesConfig } from 'react-select'

interface Props extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'placeholder'> {
    placeholder: string | null | undefined;
    error?: FieldError | undefined;
    options: {value: string,  label: string}[];
    control: any;
    name: string;
}
const AdvancedSelect = React.forwardRef((props: Props, ref: any) => {

    const { t } = useTranslation();

    const styles: StylesConfig = {
        control: (styles) => {
            return { 
                ...styles,
                maxWidth: 400,
                width: 400,
                minWidth: 400,
                borderRadius: 0,
                borderColor: 'black',
                outline: 'none',
                borderWidth: 1,
                backgroundColor: 'transparent',
                boxShadow: '',
                height: 46,
                '&:hover': ''
            }
        },
        input: (styles) => ({ ...styles, borderRadius: 0, lineHeight: 1.5, borderColor: 'black', outline: 'none', borderWidth: 1, backgroundColor: 'transparent' }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {...styles, backgroundColor: isSelected ? "#ecf1ef" : isFocused ? "#ecf1ef" : undefined, cursor: isDisabled ? 'not-allowed' : 'default',};
        },
    };

    const errorMessage = useMemo(() => {
        if (props.error?.message) {
            return props.error?.message;
        }
    }, [props.error])
    
    
    const getValues = (value: any[]) => {
        if (!value) {
            return [];
        }
        
        return props.options.filter(e => value.map((x: any) => x.toString()).includes(e.value));
    }
    
    return (
        <Controller control={props.control} name={props.name} render={({ field, fieldState, formState} ) => (
            <>
            <Flex $spaceBetween>
                <Flex $spaceBetween $alignCenter>
                    <StyledLabel htmlFor={"name"} children={props.placeholder} />
                    {!!props.error && <StyledLabel style={{fontSize: '0.8rem'}} children={errorMessage} />}
                </Flex>
                <ReactSelect
                    value={getValues(field.value)}
                    onChange={(newValue: any) => field.onChange(newValue!.map((x: any) => x.value))}
                    isMulti={!!props.multiple}
                    name={props.name}
                    options={props.options}
                    placeholder={props.placeholder ?? ''}
                    
                    noOptionsMessage={() => t('noResultsFound')}
                    styles={styles}
                />
            </Flex>
            </>
        )} />
    )
})

export default AdvancedSelect