import { createRoot } from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import moment from 'moment';
import './index.css';
import ApplicationRoutes from "Pages/ApplicationRoutes";
import './Translations';
import i18n from "i18next";
import AuthProvider from "Providers/AuthProvider";
import TranslationProvider from "Providers/TranslationProvider";
import React, { StrictMode } from 'react';
import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import 'react-toastify/dist/ReactToastify.css';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { ThemeProvider } from 'styled-components';
import { Wrapper } from "@googlemaps/react-wrapper";
import config from "Config";
import 'react-day-picker/dist/style.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
        mutations: {
            onError: (errorContent: any) => {
                let message;
                
                 if (errorContent.status === 404) {
                     message = i18n.t('errors:notFound')
                 } else if (errorContent.status === 403) {
                     message = i18n.t('errors:forbidden');
                } else if (errorContent.status === 413) {
                    message = i18n.t('errors:fileToLarge');
                 } else if (errorContent.status === 500) {
                     message = i18n.t('errors:serverErrors');
                } else if (errorContent.status === 400) {
                    if (errorContent.data !== "") {
                        message = i18n.t(errorContent.data)
                    }
        
                    const errors = _.get(errorContent, 'data.errors');
        
                    const keys = Object.keys(errors ?? {});
        
                    if (errors && keys.length > 0) {
                        message = errors[keys[0]];
                    }
                } else {
                    message = errorContent.data;
                }
    
                toast.error(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }}
})

moment.locale("da-dk", {
    week: {
        dow: 1,
        doy: 4
    },
    relativeTime: {
        future: "om %s",
        past:   "%s siden",
        s  : 'få sekunder siden',
        ss : '%d sekunder',
        m:  "et minut",
        mm: "%d minutter",
        h:  "en time",
        hh: "%d timer",
        d:  "en dag",
        dd: "%d dage",
        w:  "en uge",
        ww: "%d uger",
        M:  "en måned",
        MM: "%d måneder",
        y:  "et år",
        yy: "%d år"
    },
    months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    weekdaysShort: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
    weekdaysMin: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
});

const theme = {
    fontSize: {
        h1: '2rem',
        h2: '1.75rem',
        h3: '1.50rem',
        h4: '1.25rem',
        h5: '1rem',
        h6: '1rem',
    },
    fontWeight: {
        bold: 700,
        semiBold: 600,
        medium: 500,
    },
    colors: {
        primary: '#f8d532',
        secondary: '#fbbc05',
        success: '#017958',
        danger: '#C42828',
        warning: '#fbbc05',
        info: '#4285f4',
        light: '#ecf1ef',
        grey: '#ecf1ef',
        lightGrey: '#bdbdbd',
        primaryText: '#242424',
    },
    dimensions: {
        borderWidth: '1px',
    },
    spacing: (multiplier: number) => `${multiplier * 0.65}rem`,
    transition: {
        default: 'all 0.15s ease-in-out',
    },
};

const container = document.getElementById('root');
const root = createRoot(container!);

const render = (Component: any) => {
    return root.render(
        <StrictMode>
            <Wrapper apiKey={config.googleMaps.apiKey} region={"DK"}  >
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <TranslationProvider>
                            <AuthProvider>
                                <BrowserRouter>
                                    <Component />
                                    <ToastContainer />
                                </BrowserRouter>
                            </AuthProvider>
                        </TranslationProvider>
                    </QueryClientProvider>
                </ThemeProvider>
            </Wrapper>
        </StrictMode>
    );
};

render(ApplicationRoutes);

if (module.hot) {
    module.hot.accept('./Pages/ApplicationRoutes', () => {
        const NextApp = require('Pages/ApplicationRoutes').default;
        render(NextApp);
    });
}
