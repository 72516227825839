import * as Popover from "@radix-ui/react-popover";
import Flex from "./BaseComponents/Flex";
import StyledLabel from "./BaseComponents/Label";
import React from "react";
import StyledInput from "./BaseComponents/StyledInput";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
    value: string;
    onChange: (value: string) => void;
    name: string;
    label: string;
    error?: any;
}
const ColorPicker = (props: Props) => {
    
    const { t } = useTranslation();

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <Flex $spaceBetween>
                    <Flex $spaceBetween $alignCenter>
                        <StyledLabel htmlFor={"name"} children={props.label} />
                    </Flex>
                    <Flex style={{gap: 10}}>
                        <Color style={{backgroundColor: props.value}}  />
                        <StyledInput style={{flex: 1}} placeholder={t('search')} value={props.value} onChange={e => props.onChange(e.currentTarget.value)} />
                    </Flex>
                </Flex>
            </Popover.Trigger>
            <Popover.Portal>
                <StyledContent sideOffset={5}>
                    <Grid>
                        <Color style={{backgroundColor: '#f8d532'}} onClick={() => props.onChange("#f8d532")} />
                        <Color style={{backgroundColor: '#017958'}} onClick={() => props.onChange("#017958")} />
                        <Color style={{backgroundColor: '#C42828'}} onClick={() => props.onChange("#C42828")} />
                        <Color style={{backgroundColor: '#4285f4'}} onClick={() => props.onChange("#4285f4")} />
                        <Color style={{backgroundColor: '#242424'}} onClick={() => props.onChange("#242424")} />
                        <Color style={{backgroundColor: '#fbbc05'}} onClick={() => props.onChange("#fbbc05")} />
                    </Grid>
                    <Popover.Arrow className="PopoverArrow" />
                </StyledContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${props => props.theme.spacing(1)};
`

const Color = styled.div`
  border-radius: 100px;
  height: 50px;
  width: 50px;
  cursor: pointer;
`

const StyledContent = styled(Popover.Content)`
    padding: ${props => props.theme.spacing(1)};
    position: relative;
    background-color: white;
    border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.borderColor};
  
`

export default ColorPicker