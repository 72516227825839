import { Navigate, Route, Routes } from "react-router-dom";
import Index from "./Index";
import Edit from "./Edit";
import Create from "./Create";

const UserRoutes = () => {
    
    return (
        <Routes>
            <Route path={`create`} element={<Create />}/>
            <Route path={`edit/:id`} element={<Edit />}/>
            <Route path={`/`} element={<Index />}/>
        
            <Route path={"*"} element={<Navigate to={"/users"}/>}/>
        </Routes>
    )
}

export default UserRoutes
