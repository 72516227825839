import { request } from "Apis/BaseApi";
import { UserDto } from "./UserDto";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {PaginatedList} from "../../../Apis/Models/PaginatedList";
import {QueryParams} from "../../../Apis/Models/QueryParams";
export const USERS_CACHE_KEY = 'USERS_CACHE_KEY'
export const useUsers = (queryable: Partial<QueryParams> | null = null) => {
    return useQuery<PaginatedList<UserDto>>([USERS_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<UserDto>>({
            url: `/users`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useCustomerViewUsers = (queryable: Partial<QueryParams> | null = null) => {
    return useQuery<PaginatedList<UserDto>>([USERS_CACHE_KEY, 'CUSTOMER_VIEW', queryable], async () => {
        return await request<PaginatedList<UserDto>>({
            url: `/customerView/users`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useUser = (id: number) => {
    return useQuery<UserDto>([USERS_CACHE_KEY, id], async () => {
        return await request<UserDto>({
            url: `/users/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateUser = () => {

    const queryClient = useQueryClient();

    return useMutation(async (user: Partial<UserDto>): Promise<UserDto> => {
        if (!user.id) {
            return await request<UserDto>({
                url: `/users`,
                method: 'POST',
                data: user
            })
        } else {
            return await request<UserDto>({
                url: `/users/${user.id}`,
                method: 'PUT',
                data: user
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([USERS_CACHE_KEY])
        }
    })
}

export const useDeleteUser = () => {
    const queryClient = useQueryClient();

    return useMutation(async (user: UserDto) => {
        return await request({
            url: `/users/${user.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([USERS_CACHE_KEY])
        }
    })
}