import React, {useCallback, useEffect, useRef, useState} from "react";
import Config from "../Config";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import Flex from "./BaseComponents/Flex";
import Spinner from "./Spinner";
import {useTranslation} from "react-i18next";

interface Props {
    data?: {latitude: string, longitude: string}[];
    getMarkerTitle: (item: any) => string;
    getMarkerColor?: (item: any) => string;
    getMarkerFill?: (item: any) => string;
    getInfoWindowContent?: (item: any) => string;
}
const MarkerMap = (props: Props) => {
    
    const { data } = props;
    const { t } = useTranslation();
    
    const [mapInitialised, setMapInitialised] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const mapRef = useRef<any>(null);
    const markerClusterRef = useRef<any>(null);
    const markerRefs = useRef<any[]>([]);
    const selectedMarkerRef = useRef<any>(null);

    const initializeMap = async () => {
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

        mapRef.current = new Map(document.getElementById('map') as HTMLElement, {
            center: { lat: 56.26392, lng: 11},
            zoom: 6.5,
            mapId: Config.googleMaps.mapId,
        });
        
        setMapInitialised(true);
    }
    
    const setYouAreHere = async (lat: number, lng: number) => {
        if (!mapInitialised) {
            return;
        }

        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
        
        const glyphImg = document.createElement('img');
        glyphImg.src = '/Images/car.png';
        glyphImg.height = 40;
        glyphImg.width = 40;

        const glyphSvgPinElement = new PinElement({
            glyph: glyphImg,
            background: 'transparent',
            borderColor: 'transparent',
        });
        
        new AdvancedMarkerElement({
            map: mapRef.current,
            position: {
                lat: lat,
                lng: lng
            },
            title: t('youAreHere'),
            content: glyphSvgPinElement.element,
        })
        
        mapRef.current.setCenter({ lat: lat, lng: lng })
        mapRef.current.setZoom(18)
    }

    const positionMarkers = useCallback(async (items: { longitude: string,  latitude: string}[]) => {
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

        if (markerRefs.current.length > 0) {
            markerRefs.current.forEach((marker: any) => {
                marker.setMap(null)
            })

            markerRefs.current = []
        }

        if (markerClusterRef.current != null) {
            markerClusterRef.current.clearMarkers();
        }

        items.forEach((item) => {
            const pinBackground = new PinElement({
                borderColor: "#000",
                background: props.getMarkerColor ? props.getMarkerColor(item) : "red",
                glyphColor: props.getMarkerFill ? props.getMarkerFill(item) : "white",
            });

            const infowindow = new google.maps.InfoWindow({
                content: props.getInfoWindowContent ? props.getInfoWindowContent(item) : '',
            });

            let marker = new AdvancedMarkerElement({
                map: mapRef.current,
                position: {
                    lat: parseFloat(item.latitude),
                    lng: parseFloat(item.longitude)
                },
                title: props.getMarkerTitle ? props.getMarkerTitle(item) : '',
                content: pinBackground.element,
            })
            
            marker.addListener('click', () => {
                if (selectedMarkerRef.current) {
                    selectedMarkerRef.current.close();
                }
                infowindow.open({
                    anchor: marker,
                    map: mapRef.current,
                });
                selectedMarkerRef.current = infowindow;
            })

            markerRefs.current.push(marker)

        })

        markerClusterRef.current = new MarkerClusterer({ algorithmOptions: {maxZoom: 20}, markers: markerRefs.current, map: mapRef.current });
    }, [props]);
    
    useEffect(() => {
        initializeMap().then();
    }, []);

    useEffect(() => {
        if (navigator.geolocation && mapInitialised) {
            navigator.geolocation.getCurrentPosition((position) => {
                setYouAreHere(position.coords.latitude, position.coords.longitude).then()
            });
        }
    }, [mapInitialised]);

    useEffect(() => {
        if (mapInitialised && data) {
            positionMarkers(data).then();
        }
    }, [positionMarkers, mapInitialised, data]);

    
    return (
        <>
            {!mapInitialised && (
                <Flex $justifyCenter $alignCenter>
                    <Spinner height={75} width={75} />
                </Flex>
            )}
            <div style={{minHeight: 500, height: '100%', width: '100%'}} ref={ref} id="map" />
        </>
    )

}

export default MarkerMap