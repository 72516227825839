import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import { useAuthProvider } from "Providers/AuthProvider";
import React, {useState} from "react";
import Gap from "Components/BaseComponents/Gap";
import Box from "Components/BaseComponents/Box";
import Flex from "Components/BaseComponents/Flex";
import { H1 } from "Components/BaseComponents/Heading";
import ControlledInput from "../../../Components/ControlledInput";
import styled from "styled-components";
import {Link} from "react-router-dom";

interface ILoginDetails {
    email: string;
    password: string;
}

const LoginForm = () => {
    
    const { t } = useTranslation();
    
    const { signIn } = useAuthProvider();
    
    const { register, formState: { errors }, handleSubmit } = useForm<ILoginDetails>({
        reValidateMode: "onChange"
    })
    
    const [loading, setLoading] = useState<boolean>(false);
    
    const login = async (data: ILoginDetails) => {
        setLoading(true);
        
        await signIn(data);
        
        setLoading(false);
    }
    
    return (
        <form onSubmit={handleSubmit(login)}>
            <Box>
                <Gap $vertical $block>
                    <Flex $justifyCenter>
                        <H1>{t('login')}</H1>
                    </Flex>
                    <ControlledInput placeholder={t('email')} error={errors.email} {...register('email', {required: true})} />
                    <ControlledInput placeholder={t('password')} type={"password"} error={errors.password} {...register('password', {
                        required: true,
                        minLength: {
                            value: 6,
                            message: t('validation.minimum6')}
                        }
                    )} />
                    
                    <Flex $spaceBetween $alignCenter>
                        <StyledLink to="/resetPassword">{t('gotoResetPassword')}</StyledLink>
                        <Button loading={loading} type={"submit"}>
                            {t('login')}
                        </Button>
                    </Flex>
                </Gap>
            </Box>
        </form>
    )
}

const StyledLink = styled(Link)`
    color: ${props => props.theme.colors.primaryText};
`
export default LoginForm
