import List from "Components/List";
import { useTranslation } from "react-i18next";
import Gap from "../../../Components/BaseComponents/Gap";
import {useCustomerViewBlocks} from "../Services/BlockApi";
import {useCustomerViewUsers} from "../../Users/Services/UsersApi";
import { BlockServiceDto } from "../Services/BlockServiceDto";
import moment from "moment/moment";
import Clickable from "../../../Components/BaseComponents/Clickable";
import {FaDownload, FaEye} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {customerViewDownloadBlockServiceReport} from "../Services/BlockServiceApi";

interface Props {
    data?: BlockServiceDto[];
    isLoading: boolean;
}
const CustomerViewBlockServiceList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const { data: blocks } = useCustomerViewBlocks();
    const { data: employees } = useCustomerViewUsers();

    const getBlockAddress = (blockService: BlockServiceDto) => {
        let block = blocks?.items.find(x => x.id === blockService.blockId);

        if (!block) {
            return '';
        }

        return `${block.address}, ${block.zipCode} ${block.city}`;
    }

    const downloadReport = async (blockService: BlockServiceDto) => {
        await customerViewDownloadBlockServiceReport(blockService.id!, `${t('serviceReport')}.pdf`);
    }
    
    return (
        <List<BlockServiceDto> data={props.data} loading={props.isLoading} columns={[
            {
                title: t('serialNumber'),
                textRender: (record) => blocks?.items.find(x => x.id === record.blockId)!.serialNumber!
            },
            {
                title: t('date'),
                textRender: (record) => moment(record.serviceDate).format('DD / MM / YYYY')
            },
            {
                title: t('employee'),
                textRender: (record) => employees?.items.find(x => x.id === record.employeeId)?.name ?? ""
            },
            {
                title: t('nextServiceDate'),
                textRender: (record) => moment(record.nextService).format('DD / MM / YYYY')
            },
            {
                title: t('address'),
                textRender: (record) => getBlockAddress(record)
            },
            {
                title: t('hasAlarm'),
                textRender: (record) => record.hasAlarm ? t('yes') : t('no')
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                    <Gap>
                        <Clickable onClick={() => downloadReport(record)}><FaDownload />{t('download')}</Clickable>
                        <Clickable onClick={() => navigate(`/blocks/services/report/${record.id}`)}><FaEye />{t('show')}</Clickable>
                    </Gap>
                )
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default CustomerViewBlockServiceList