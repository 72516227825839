import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {CameraLocationDto, MutateCameraLocationDto} from "./CameraLocationDto";
import {PaginatedList} from "../../../Apis/Models/PaginatedList";
import {QueryParams} from "../../../Apis/Models/QueryParams";

export const CAMERA_LOCATION_CACHE_KEY = 'CAMERA_LOCATION_CACHE_KEY'
export const useCameraLocations = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<CameraLocationDto>, unknown>([CAMERA_LOCATION_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<CameraLocationDto>>({
            url: `/cameras/locations`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useCameraLocation = (id: number) => {
    return useQuery<CameraLocationDto, unknown>([CAMERA_LOCATION_CACHE_KEY, id], async () => {
        return await request<CameraLocationDto>({
            url: `/cameras/locations/${id}`,
            method: 'GET',
        })});
}

export const useMutateCameraLocation = () => {
    const queryClient = useQueryClient();

    return useMutation(async (cameraLocation: MutateCameraLocationDto) => {
        if (!cameraLocation.id) {
            return await request({
                url: `/cameras/locations`,
                method: 'POST',
                data: cameraLocation
            })
        } else {
            return await request({
                url: `/cameras/locations/${cameraLocation.id}`,
                method: 'PUT',
                data: cameraLocation
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CAMERA_LOCATION_CACHE_KEY])
        }
    })
}

export const useDeleteCameraLocation = () => {
    const queryClient = useQueryClient();

    return useMutation(async (cameraLocation: CameraLocationDto) => {
        return await request({
            url: `/cameras/locations/${cameraLocation.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CAMERA_LOCATION_CACHE_KEY])
        }
    })
}