import { H1 } from "Components/BaseComponents/Heading";
import React from "react";
import { useTranslation } from "react-i18next";
import Menu from "Layouts/Menu";
import Flex from "Components/BaseComponents/Flex";
import StyledInput from "Components/BaseComponents/StyledInput";
import Gap from "Components/BaseComponents/Gap";
import {useCustomerViewBlockServices} from "Features/Blocks/Services/BlockServiceApi";
import Pagination from "Components/Pagination";
import useQueryParams from "Hooks/UseQueryParams";
import CustomerViewBlockServiceList from "../../Features/Blocks/Components/CustomerViewBlockServiceList";

const BlocksServices = () => {

    const { t } = useTranslation();
    const [ filter, setFilter ] = useQueryParams({page: 0, pageSize: 25});

    const { data, isLoading } = useCustomerViewBlockServices(filter, true)
    
    return (
        <>
            <Flex $spaceBetween>
                <H1>{t('blockServices')}</H1>
            </Flex>
            
            <Gap $vertical>
                <Menu menuItems={[
                    {
                        label: t('menu.blocks'),
                        to: "/blocks",
                        exact: true
                    },
                    {
                        label: t('menu.blockServices'),
                        to: "/blocks/services",
                    }
                ]}  />
    
                <StyledInput $block placeholder={t('search')} onChange={e => setFilter({search: e.currentTarget.value})} />
                
                <CustomerViewBlockServiceList data={data?.items} isLoading={isLoading}  />

                <Pagination paginatedList={data} onPageChange={newPage => setFilter({page: newPage})} />
            </Gap>
        </>
    )
}


export default BlocksServices