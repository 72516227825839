import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import { useTranslation } from "react-i18next";
import {CustomerForm} from "Features/Customers";
import {useNavigate, useParams} from "react-router-dom";
import {useCustomer} from "../../Features/Customers/Services/CustomerApi";
import ContentLoader from "../../Components/ContentLoader";

const Edit = () => {

    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data, isLoading } = useCustomer(parseInt(id!))
    
    const navigate = useNavigate();
    
    return (
        <ContentLoader shown={isLoading}>
            <H1 style={{paddingBottom: 10}}>{t('editCustomer')}</H1>
            
            <CustomerForm customer={data} onSubmit={() => navigate(-1)}  />
        </ContentLoader>
    )

}

export default Edit