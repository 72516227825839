import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {CameraDto, MutateCameraDto} from "./CameraDto";
import {QueryParams} from "../../../Apis/Models/QueryParams";
import { PaginatedList } from "Apis/Models/PaginatedList";

export const CAMERA_CACHE_KEY = 'CAMERA_CACHE_KEY'
export const useCameras = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<CameraDto>, unknown>([CAMERA_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<CameraDto>>({
            url: `/cameras`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useCamera = (id: number) => {
    return useQuery<CameraDto, unknown>([CAMERA_CACHE_KEY, id], async () => {
        return await request<CameraDto>({
            url: `/cameras/${id}`,
            method: 'GET',
        })});
}

export const useMutateCamera = () => {
    const queryClient = useQueryClient();

    return useMutation(async (camera: MutateCameraDto) => {
        if (!camera.id) {
            return await request({
                url: `/cameras`,
                method: 'POST',
                data: camera
            })
        } else {
            return await request({
                url: `/cameras/${camera.id}`,
                method: 'PUT',
                data: camera
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CAMERA_CACHE_KEY])
        }
    })
}

export const useDeleteCamera = () => {
    const queryClient = useQueryClient();

    return useMutation(async (camera: CameraDto) => {
        return await request({
            url: `/cameras/${camera.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CAMERA_CACHE_KEY])
        }
    })
}