import Spinner from './Spinner';
import styles from "./PageLoader.module.css";

interface Props {
    shown: boolean;
    children: any;
}
const PageLoader = ({shown, children}: Props) => {

    return (
        <>
            <div className={`${styles.pageLoader} ${!shown ? styles.hidden :''}`}>
                <Spinner height={75} width={75} />
            </div>
            <div className={`${styles.content} ${shown ? styles.hidden :''}`}>
                {!shown && (
                    <>
                        {children}
                    </>
                )}
            </div>
        </>
    )
}

export default PageLoader
