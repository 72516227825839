import {ReactElement, useMemo} from "react";
import React from "react";
import Flex from "Components/BaseComponents/Flex";
import Spinner from "../Spinner";
import styled, {css} from "styled-components";
import { useTranslation } from "react-i18next";

export interface IColumnProps<T> {
    icon?: ReactElement
    customRender?: (record: T) => ReactElement,
    textRender?: (record: T) => string | number | null | undefined
    title?: string | any;
    size?: 'auto' | 'max-content' | 'min-content' | string;
    columnKey?: string;
}

interface IProps<T> {
    loading?: boolean;
    data?: T[];
    columns: IColumnProps<T>[];
    emptyText?: string;
    keyExtractor: (record: T) => string;
    onTitleClick?: (columnKey?: string) => void;
}

const List = <T, >(props: IProps<T>) => {

    const { t } = useTranslation();
    
    const {loading, data, columns, emptyText, keyExtractor} = props;

    const getGridCss = () => {
        return columns.map(x => x.size ?? '1fr').join(' ')
    }

    const rows = () => {
        return data?.map((record: T, rowIndex) => (
            <React.Fragment key={keyExtractor(record)}>
                {columns.map((column, index) => (
                    <React.Fragment key={index}>
                        <Column $odd={rowIndex % 2 === 0}>
                            {column.customRender ? column.customRender(record) : column.textRender && column.textRender(record)}
                        </Column>
                    </React.Fragment>
                ))}
            </React.Fragment>
        ))
    };

    if (loading) {
        return (
            <Flex $justifyCenter $alignCenter>
                <Spinner height={100} width={100}/>
            </Flex>
        )
    }

    if (data?.length === 0) {
        return (
            <Flex $justifyCenter $alignCenter>
                {emptyText ?? t('noResultsFound')}
            </Flex>
        )
    }

    return (
        <Container style={{gridTemplateColumns: getGridCss()}}>
            {columns.map((column, index) => (
                <TitleColumn key={index} onClick={() => props.onTitleClick && props.onTitleClick(column.columnKey)}>
                    {column.title}
                </TitleColumn>
            ))}
            {rows()}
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  display: grid;

  grid-auto-rows: min-content;
`

const TitleColumn = styled.div`
  font-weight: bold;
  white-space: nowrap;
  padding: ${props => props.theme.spacing(1)};
`

const Column = styled.div<{ $odd: boolean }>`
  padding: ${props => props.theme.spacing(1)};

  ${props => props.$odd && css`
    background-color: ${props => props.theme.colors.light};
  `}
`

export default List
