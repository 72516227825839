import Flex from "Components/BaseComponents/Flex";
import { H1 } from "Components/BaseComponents/Heading";
import Button from "Components/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Menu from "../../../Layouts/Menu";
import ContactList from "../../../Features/Customers/Components/ContactList";
import {useContacts} from "../../../Features/Customers/Services/ContactApi";
import StyledInput from "../../../Components/BaseComponents/StyledInput";
import Gap from "Components/BaseComponents/Gap";
import Pagination from "../../../Components/Pagination";
import useQueryParams from "Hooks/UseQueryParams";

const Index = () => {

    const navigate = useNavigate();
    
    const { t } = useTranslation();
    const [ filter, setFilter ] = useQueryParams({page: 0, pageSize: 25});
    
    const { data, isLoading } = useContacts(filter, true);

    return (
        <>
            <Flex $spaceBetween>
                <H1>{t('contacts')}</H1>
                <div>
                    <Button onClick={() => navigate("/customers/contacts/create")} color={"secondary"}>{t('createContact')}</Button>
                </div>
            </Flex>

            <Gap $vertical>
                <Menu menuItems={[
                    {
                        label: t('menu.customers'),
                        to: "/customers",
                        exact: true
                    },
                    {
                        label: t('menu.contacts'),
                        to: "/customers/contacts"
                    }
                ]}  />
    
                <StyledInput $block placeholder={t('search')} onChange={e => setFilter({search: e.currentTarget.value})} />
                
                <ContactList data={data?.items} isLoading={isLoading}  />

                <Pagination paginatedList={data} onPageChange={newPage => setFilter({page: newPage})} />
            </Gap>
        </>
    )

}

export default Index