import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {BlockTypeDto, MutateBlockTypeDto} from "./BlockTypeDto";
import {QueryParams} from "../../../Apis/Models/QueryParams";
import {PaginatedList} from "../../../Apis/Models/PaginatedList";

export const BLOCK_TYPE_CACHE_KEY = 'BLOCK_TYPE_CACHE_KEY'
export const useBlockTypes = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<BlockTypeDto>, unknown>([BLOCK_TYPE_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<BlockTypeDto>>({
            url: `/blocks/types`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useBlockType = (id: number) => {
    return useQuery<BlockTypeDto, unknown>([BLOCK_TYPE_CACHE_KEY, id], async () => {
        return await request<BlockTypeDto>({
            url: `/blocks/types/${id}`,
            method: 'GET',
        })});
}

export const useMutateBlockType = () => {
    const queryClient = useQueryClient();

    return useMutation(async (blockType: MutateBlockTypeDto) => {
        if (!blockType.id) {
            return await request({
                url: `/blocks/types`,
                method: 'POST',
                data: blockType
            })
        } else {
            return await request({
                url: `/blocks/types/${blockType.id}`,
                method: 'PUT',
                data: blockType
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_TYPE_CACHE_KEY])
        }
    })
}

export const useDeleteBlockType = () => {
    const queryClient = useQueryClient();

    return useMutation(async (blockType: BlockTypeDto) => {
        return await request({
            url: `/blocks/types/${blockType.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_TYPE_CACHE_KEY])
        }
    })
}