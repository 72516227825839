import { Navigate, Route, Routes } from "react-router-dom";
import ContactsCreate from "./Contacts/Create";
import ContactsEdit from "./Contacts/Edit";
import ContactsIndex from "./Contacts/Index";
import Create from "./Create";
import Edit from "./Edit";
import Index from "./Index";

const CustomerRoutes = () => {
    
    return (
        <Routes>
            <Route path={`contacts/create`} element={<ContactsCreate />}/>
            <Route path={`contacts/edit/:id`} element={<ContactsEdit />}/>
            <Route path={`contacts`} element={<ContactsIndex />}/>

            <Route path={`create`} element={<Create />}/>
            <Route path={`edit/:id`} element={<Edit />}/>
            <Route path={`/`} element={<Index />}/>
            
            <Route path={"*"} element={<Navigate to={"/customers"}/>}/>
        </Routes>
    )
}

export default CustomerRoutes
