import Flex from "Components/BaseComponents/Flex";
import ControlledInput from "Components/ControlledInput";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import {useForm } from "react-hook-form";
import useToasts from "Hooks/UseToasts";
import React, {useEffect} from "react";
import {CameraLocationDto, MutateCameraLocationDto} from "../Services/CameraLocationDto";
import {useMutateCameraLocation} from "../Services/CameraLocationApi";
import { StyledForm } from "Components/BaseComponents/StyledForm";

interface Props {
    entity?: CameraLocationDto;
    onSubmit?: () => void;
}
const CameraLocationForm = (props: Props) => {

    const { t } = useTranslation();    
    
    const { showSuccessToast } = useToasts();
    
    const { 
        register,
        handleSubmit,
        reset, 
        formState: { errors }
    } = useForm<MutateCameraLocationDto>();
    
    const { mutateAsync, isLoading } = useMutateCameraLocation();
    
    useEffect(() => {
        if (!!props.entity) {
            reset(props.entity)
        }
    }, [reset,  props.entity]);
    
    const onSubmit = async (blockType: MutateCameraLocationDto) => {
        await mutateAsync(blockType);
        
        showSuccessToast(t('cameraLocationSaved'));
        
        props.onSubmit?.();
    }
    
    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput placeholder={t('name')} error={errors.name} {...register('name', {required: true})} />
            
            <Flex $justifyEnd>
                <Button loading={isLoading} type={"submit"}>
                    {t('save')}
                </Button>
            </Flex>
        </StyledForm>
    )
}

export default CameraLocationForm