import styled from "styled-components";
import StyledButton from "./BaseComponents/StyledButton";
import Spinner from "./Spinner";

interface Props {
    loading?: boolean;
    disabled?: boolean;
    type: 'submit' | 'button';
    color?: 'default' | 'secondary' | 'danger';
    onClick?: (e: any) => void;
    children: string;
}
const Button = (props: Props) => {

    return (
        <StyledButton onClick={props.onClick} $color={props.color ?? 'default'}>
            <SpinnerContainer $visible={props.loading ?? false}>
                <Spinner />
            </SpinnerContainer>
            {props.children}
        </StyledButton>
    )
}

const SpinnerContainer = styled.div<{$visible: boolean}>`
  width: ${props => props.$visible ? '30px' : '0px'};
  transition: ${props => props.theme.transition.default};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`


Button.defaultProps = {
    type: 'button'
}

export default Button