import {H1} from "Components/BaseComponents/Heading";
import CameraLocationForm from "Features/Cameras/Components/CameraLocationForm";

import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const Create = () => {

    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <>
            <H1 style={{paddingBottom: 10}}>{t('createCameraLocation')}</H1>

            <CameraLocationForm onSubmit={() => navigate(-1)}  />
        </>
    )

}

export default Create