import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {ContactDto, MutateContactDto} from "./ContactDto";
import {PaginatedList} from "Apis/Models/PaginatedList";
import {QueryParams} from "Apis/Models/QueryParams";

export const CONTACTS_CACHE_KEY = 'CONTACTS_CACHE_KEY'
export const useContacts = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<ContactDto>, unknown>([CONTACTS_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<ContactDto>>({
            url: `/contacts`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useCustomerViewContacts = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<ContactDto>, unknown>([CONTACTS_CACHE_KEY, 'CUSTOMER_VIEW', queryable], async () => {
        return await request<PaginatedList<ContactDto>>({
            url: `/customerView/contacts`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}


export const useContact = (id: number) => {
    return useQuery<ContactDto, unknown>([CONTACTS_CACHE_KEY, id], async () => {
        return await request<ContactDto>({
            url: `/contacts/${id}`,
            method: 'GET',
        })});
}

export const useMutateContact = () => {
    const queryClient = useQueryClient();

    return useMutation(async (contact: MutateContactDto) => {
        if (!contact.id) {
            return await request({
                url: `/contacts`,
                method: 'POST',
                data: contact
            })
        } else {
            return await request({
                url: `/contacts/${contact.id}`,
                method: 'PUT',
                data: contact
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CONTACTS_CACHE_KEY])
        }
    })
}

export const useDeleteContact = () => {
    const queryClient = useQueryClient();

    return useMutation(async (contact: ContactDto) => {
        return await request({
            url: `/contacts/${contact.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CONTACTS_CACHE_KEY])
        }
    })
}