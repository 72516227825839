import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ContentLoader from "Components/ContentLoader";
import BlockTypeForm from "Features/Blocks/Components/BlockTypeForm";
import {useBlockType} from "../../../Features/Blocks/Services/BlockTypeApi";

const Edit = () => {

    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data, isLoading } = useBlockType(parseInt(id!))
    
    const navigate = useNavigate();
    
    return (
        <ContentLoader shown={isLoading}>
            <H1 style={{paddingBottom: 10}}>{t('editBlockType')}</H1>
            
            <BlockTypeForm entity={data} onSubmit={() => navigate(-1)}  />
        </ContentLoader>
    )

}

export default Edit