import styled, { css } from "styled-components";

export default styled.input<{$error?: boolean; $block?: boolean}>`
    all: unset;
    box-sizing: border-box; 
    border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.colors.primaryText};
    padding: ${props => props.theme.spacing(1)};
    line-height: 1.5;

    ${props => props.$block && css `
        width: 100%;
    `}
  
    ${props => !props.$block && css `
        width: 400px;
        max-width: 400px;
        min-width: 400px;
    `}
  
    ${props => props.$error && css `
        border-color: ${props => props.theme.colors.danger};
    `}
`