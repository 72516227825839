import React, {useMemo, useState} from "react";
import moment from "moment";
import * as Popover from '@radix-ui/react-popover';
import {DayPicker} from "react-day-picker";
import da from "date-fns/locale/da";
import styled, { css } from "styled-components";
import StyledLabel from "./BaseComponents/Label";
import Flex from "./BaseComponents/Flex";
import {useTranslation} from "react-i18next";
import Button from "./Button";

interface Props {
    value: string;
    onChange: (value: string | null) => void;
    name: string;
    label: string;
    error?: any;
}
const DateTimePicker = (props: Props) => {

    const { t } = useTranslation();
    
    const [open, setOpen] = useState<boolean>(false);

    const errorMessage = useMemo(() => {
        if (props.error?.message) {
            return props.error?.message;
        }
    }, [props.error])
    
    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Flex $spaceBetween $alignCenter>
                <Flex $spaceBetween $alignCenter>
                    <StyledLabel htmlFor={"name"} children={props.label} />
                    {!!props.error && <StyledLabel style={{fontSize: '0.8rem'}} children={errorMessage} />}
                </Flex>
                <Popover.Trigger asChild>
                    <StyledDiv $error={!!props.error}>
                        {props.value ? moment(props.value).format('DD-MM-YYYY') : t('notSet')}
                    </StyledDiv>
                </Popover.Trigger>
            </Flex>
            <Popover.Portal>
                <StyledContent sideOffset={5}>
                    <DayPicker selected={moment(props.value).toDate()} mode={'single'} locale={da} onSelect={e => {
                        !!e && props.onChange(e!.toISOString());
                        setOpen(false);
                    }} />
                    <Button onClick={() => {
                        props.onChange(null);
                        setOpen(false);
                    }} color={"secondary"}>{t('reset')}</Button>
                    <Popover.Arrow className="PopoverArrow" />
                </StyledContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

const StyledDiv = styled.div<{ $error?: boolean }>`
    border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.borderColor};
    padding: ${props => props.theme.spacing(1)};
    width: 400px;
    ${props => props.$error && css `
        border-color: ${props => props.theme.colors.danger};
    `};
    line-height: 1.65
`

const StyledContent = styled(Popover.Content)`
    padding-top: ${props => props.theme.spacing(1)};
    position: relative;
    background-color: white;
    border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.borderColor};
`

export default DateTimePicker