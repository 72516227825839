export interface UserDto {
    id: number;
    name: string;
    email: string;
    phone: string;
    accessLevel: AccessLevel;
}

export enum AccessLevel {
    GOD = 1000,
    Admin = 300,
    Employee = 200,
    Customer = 100,
    Utility = 99
}