import styled, { css } from "styled-components";

export default styled.div<{ $vertical?: boolean; $size?: number; $block?: boolean; }>`
  display: flex;

  ${props => props.$vertical && css`
    flex-direction: column;
  `}
  
  ${props => props.$block && css`
    width: 100%;
  `}
  
  > * {
    flex: 1;

    &:not(:first-child) {
      ${props => props.$vertical ? "margin-top" : "margin-left"}: ${props => props.theme.spacing(props.$size || 2)};
    }
  }
`;