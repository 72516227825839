import { H1 } from "Components/BaseComponents/Heading";
import {CustomerApi, CustomerList} from "Features/Customers";
import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import Menu from "../../Layouts/Menu";
import Button from "../../Components/Button";
import Flex from "../../Components/BaseComponents/Flex";
import {useNavigate} from "react-router-dom";
import StyledInput from "../../Components/BaseComponents/StyledInput";
import Gap from "Components/BaseComponents/Gap";
import useQueryParams from "../../Hooks/UseQueryParams";
import Pagination from "../../Components/Pagination";

const Index = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ filter, setFilter ] = useQueryParams({page: 0, pageSize: 25});

    const { data, isLoading } = CustomerApi.useCustomers(filter, true);
    
    return (
        <>
            <Flex $spaceBetween>
                <H1>{t('customers')}</H1>
                <div>
                    <Button onClick={() => navigate("create")} color={"secondary"}>{t('createCustomer')}</Button>
                </div>
            </Flex>
            
            <Gap $vertical>
                <Menu menuItems={[
                    {
                        label: t('menu.customers'),
                        to: "/customers"
                    },
                    {
                        label: t('menu.contacts'),
                        to: "/customers/contacts"
                    }
                ]}  />
    
                <StyledInput $block placeholder={t('search')} onChange={e => setFilter({search: e.currentTarget.value})} />
                
                <CustomerList data={data?.items} isLoading={isLoading}  />

                <Pagination paginatedList={data} onPageChange={newPage => setFilter({page: newPage})} />
            </Gap>
        </>
    )
}


export default Index