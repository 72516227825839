import {FieldError} from "react-hook-form";
import React, {useMemo} from "react";
import Textarea from "./Textarea";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    placeholder: string | undefined | any;
    defaultValue?: string;
    error?: FieldError | undefined;
}

const ControlledTextarea = React.forwardRef((props: Props, ref) =>  {
    
    const errorMessage = useMemo(() => {
        if (props.error?.message) {
            return props.error?.message;
        }
    }, [props.error])
    
    return (
        <Textarea hasError={!!props.error} secondaryLabel={errorMessage} ref={ref} {...props} />
    )
})


export default ControlledTextarea