import {useQuery} from "@tanstack/react-query";
import { request } from "./BaseApi";
import { VatInformationDto } from "./Models/VatInformationDto";

export const VATINFORMATION_SEARCH_CACHE_KEY = "VATINFORMATION_SEARCH_CACHE_KEY"
export const useVatInformation = (query: string) => {
    return useQuery([VATINFORMATION_SEARCH_CACHE_KEY, query], async () => {
        return request<VatInformationDto[]>({
            url: '/vatinformation?query=' + query,
            method: 'GET'
        })
    }, {
        enabled: !!query
    })
}
