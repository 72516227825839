import {NavLink} from "react-router-dom";
import styled from "styled-components"

export interface MenuItemProps {
    label: string;
    to: string;
    exact?: boolean;
}

interface Props {
    menuItems: MenuItemProps[];
    before?: any;
    end?: any;
}

const Menu = (props: Props) => {

    return (
        <MenuContainer>
            {!!props.before && (
                <>  
                    {props.before} 
                </>
            )}
            {props.menuItems.map((item, index) => (
                    <MenuItem key={item.to} to={item.to} end={item.exact} $activeClassName={'active'}>
                        {item.label}
                    </MenuItem>
                )
            )}
            {!!props.end && (
                <>
                    <div style={{flex: 1}}></div>
                    {props.end}
                </>
            )}
        </MenuContainer>
    )
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: ${props => props.theme.spacing(1)};
  align-items: flex-end;
  width: 100%;
  gap: ${props => props.theme.spacing(4)};

  border-bottom: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.colors.light};
`

const MenuItem = styled(NavLink)<{ $activeClassName?: string }>`
  all: unset;

  cursor: pointer;

  font-size: ${props => props.theme.fontSize.h5};

  &:hover {
    &:after {
      width: 100%;
    }
  }

  &.${props => props.$activeClassName} {
    &:after {
      width: 100%;
    }
  }

  &:after {
    margin-top: ${props => props.theme.spacing(1)};
    content: "";
    width: 0;
    display: block;
    background-color: ${props => props.theme.colors.success};
    height: calc(${props => props.theme.dimensions.borderWidth} * 2);
    transition: all 0.15s ease-in-out;
  }
`

export default Menu