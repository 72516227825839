import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import useToasts from "Hooks/UseToasts";
import React, {useEffect, useState} from "react";
import Select from "../../../Components/Select";
import {useBlocks} from "../Services/BlockApi";
import ContentLoader from "Components/ContentLoader";
import DateTimePicker from "../../../Components/DateTimePicker";
import {BlockServiceDto, MutateBlockServiceDto} from "../Services/BlockServiceDto";
import {useUsers} from "../../Users/Services/UsersApi";
import {downloadBlockServiceReport, useMutateBlockService} from "../Services/BlockServiceApi";
import {AccessLevel} from "../../Users/Services/UserDto";
import {useAuthProvider} from "../../../Providers/AuthProvider";
import {useBlockSubscriptions} from "Features/Blocks/Services/BlockSubscriptionApi";
import moment from "moment";
import {SchemaFormBuilder} from "../../Schema";
import {useCurrentSchemaDefinitions, useSchemaDefinitions} from "../../Schema/Services/SchemaDefinitionApi";
import {SchemaDto} from "../../Schema/Services/SchemaDto";
import { StyledForm } from "Components/BaseComponents/StyledForm";
import Button from "Components/Button";
import Flex from "../../../Components/BaseComponents/Flex";
import {BlockDto} from "../Services/BlockDto";

interface Props {
    entity?: Partial<BlockServiceDto>;
    onSubmit?: () => void;
}
const BlockServiceForm = (props: Props) => {

    const { t } = useTranslation();
    const { userContext } = useAuthProvider();
    const { showSuccessToast } = useToasts();

    const [ selectedBlock, setSelectedBlock ] = useState<BlockDto>();

    const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
    
    const { data: schemas, isLoading: isFetchingSchemas } = useSchemaDefinitions();
    const { data: currentSchemas, isLoading: isFetchingCurrentSchemas } = useCurrentSchemaDefinitions();

    const { data: blockSubscriptions, isLoading: isFetchingBlockSubscriptions } = useBlockSubscriptions();
    const { data: blocks, isLoading: isFetchingBlocks } = useBlocks();
    const { data: users, isLoading: isFetchingUsers } = useUsers();

    const downloadReport = async () => {
        setIsDownloadingReport(true);
        try {
            await downloadBlockServiceReport(props.entity?.id!, `${t('serviceReport')}.pdf`);
        }
        finally {
            setIsDownloadingReport(false);
        }
    }

    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {
            errors
        }
    } = useForm<MutateBlockServiceDto>();

    const { mutateAsync, isLoading } = useMutateBlockService();

    useEffect(() => {
        if (props.entity?.id) {
            reset({...props.entity});
            let block = blocks?.items.find(x => x.id === props.entity?.blockId);
            setSelectedBlock(block)
        } else {
            let entity = props.entity ?? {};

            entity.employeeId = userContext!.id;
            entity.serviceDate = new Date().toISOString();

            if (entity.blockId) {
                let block = blocks?.items.find(x => x.id === entity.blockId);
                setSelectedBlock(block)
                const blockSubscription = blockSubscriptions?.items.find(x => x.id === block?.blockSubscriptionId)

                if (blockSubscription?.interval) {
                    entity.nextService = moment().add(blockSubscription.interval, 'months').toISOString();
                }
            }

            reset(entity);
        }
    }, [userContext, reset,  props.entity, blocks, blockSubscriptions]);

    const blockSubscription = () => {
        return blockSubscriptions?.items.find(x => x.id === selectedBlock?.blockSubscriptionId);
    }

    const getSchema = () => {
        if (!!props.entity?.schema?.schemaDefinitionId) {
            return schemas?.find(x => x.id === props.entity?.schema?.schemaDefinitionId);
        }

        if (!selectedBlock) {
            return null;
        }

        if (!currentSchemas || currentSchemas.length === 0) {
            return null;

        }

        const blockSubscription = blockSubscriptions?.items.find(x => x.id === selectedBlock.blockSubscriptionId);

        return currentSchemas && currentSchemas.find(x => x.schemeName === blockSubscription!.schemeName);
    }

    const saveService = async (schema: SchemaDto) => {
        await handleSubmit(async (blockService) => {
            await mutateAsync({
                ...blockService,
                schema: schema
            });

            showSuccessToast(t('blockServiceSaved'));

            props.onSubmit?.();
        })()
    }

    const onBlockSelected = (blockId: number) => {
        let blockSubscriptionId = blocks!.items.find(x => x.id === blockId)!.blockSubscriptionId;

        setSelectedBlock(blocks!.items.find(x => x.id === blockId));

        const blockSubscription = blockSubscriptions?.items.find(x => x.id === blockSubscriptionId);

        if (blockSubscription?.interval) {
            setValue('nextService', moment().add(blockSubscription.interval, 'months').toISOString());
        }
    }

    return (
        <ContentLoader shown={(isFetchingBlocks || isFetchingUsers || isFetchingBlockSubscriptions || isFetchingSchemas || isFetchingCurrentSchemas || (!!selectedBlock && !blockSubscription()))}>
            <StyledForm>
                <Select placeholder={t(`schemeName.${blockSubscription()?.schemeName}`)} error={errors.blockId} {...register('blockId', { required: true})} onChange={e => onBlockSelected(parseInt(e.currentTarget.value))} >
                    <option value={''}>{t('select')}</option>
                    {blocks?.items.sort((a, b) => a.serialNumber > b.serialNumber ? 1 : -1).map(block => (
                        <option key={block.id} value={block.id}>{block.serialNumber}</option>
                    ))}
                </Select>

                <Controller control={control} name={"serviceDate"} rules={{required: true}} render={({ field }) => (
                    <DateTimePicker name={field.name} label={t('serviceDate')} value={field.value} onChange={field.onChange}></DateTimePicker>
                )} />

                <Select placeholder={t('employee')} error={errors.employeeId} {...register('employeeId')} >
                    <option value={''}>{t('select')}</option>
                    {users?.items.filter(c => c.accessLevel >= AccessLevel.Employee).map(employee => (
                        <option key={employee.id} value={employee.id}>{employee.name}</option>
                    ))}
                </Select>

                <Controller control={control} name={"nextService"} rules={{required: true}} render={({ field }) => (
                    <DateTimePicker name={field.name} label={t('nextService')} value={field.value} onChange={field.onChange}></DateTimePicker>
                )} />

                {!!getSchema() && (
                    <SchemaFormBuilder schemaDefinition={getSchema()!} schema={props.entity?.schema} onSave={saveService} isLoading={isLoading} />
                )}

            </StyledForm>
            
            {!!props.entity?.id && (
                <Flex $justifyEnd style={{paddingTop: 20}}>
                    <Button loading={isDownloadingReport} onClick={downloadReport} type={"button"} color={"secondary"}>
                        {t('downloadReport')}
                    </Button>
                </Flex>
            )} 
        </ContentLoader>
    )
}

export default BlockServiceForm
