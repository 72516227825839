import { useTranslation } from "react-i18next";
import { useAuthProvider } from "Providers/AuthProvider";
import { useForm } from "react-hook-form";
import Button from "Components/Button";
import {Link, useNavigate} from "react-router-dom";
import ControlledInput from "../../../Components/ControlledInput";
import React, {useState} from "react";
import Box from "Components/BaseComponents/Box";
import Flex from "../../../Components/BaseComponents/Flex";
import {H1} from "../../../Components/BaseComponents/Heading";
import Gap from "Components/BaseComponents/Gap";
import styled from "styled-components";

interface EmailField {
    email: string
}
const ResetPasswordForm = () => {
    
    const { t } = useTranslation();
    
    const { resetPassword } = useAuthProvider();

    const [loading, setLoading] = useState<boolean>(false)
    
    const { register, formState: { errors }, handleSubmit } = useForm<EmailField>();

    const navigate = useNavigate();
    
    const login = async (data: EmailField) => {
        setLoading(true);
        
        await resetPassword(data);
        
        setLoading(false);
        
        navigate('/signin');
    }
    
    return (
        <form onSubmit={handleSubmit(login)}>
            <Box>
                <Gap $vertical $block>
                    <Flex $justifyCenter>
                        <H1>{t('resetPassword')}</H1>
                    </Flex>
                    
                    <ControlledInput placeholder={t('email')} error={errors.email} {...register('email', {
                        required: true
                    })} />
                    
                    <Flex $spaceBetween $alignCenter>
                        <StyledLink to="/signin">{t('gotoSignIn')}</StyledLink>
                        <Button loading={loading} type={"submit"} children={t('resetPassword')} color={"default"} />
                    </Flex>
                </Gap>
            </Box>
        </form>
    )
}

const StyledLink = styled(Link)`
    color: ${props => props.theme.colors.primaryText};
`

export default ResetPasswordForm
