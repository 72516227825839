import {Navigate, Route, Routes} from "react-router-dom";
import SignIn from "Pages/SignIn";
import RequireAuthentication from "Components/RequireAuthentication";
import RedirectIfLoggedIn from "Components/RedirectIfLoggedIn";
import ResetPassword from "Pages/ResetPassword";
import AuthActions from "Pages/AuthActions";
import DefaultLayout from "Layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import CustomerRoutes from "./Customers/CustomerRoutes";
import UserRoutes from "./Users/UserRoutes";
import BlockRoutes from "./Blocks/BlockRoutes";
import CameraRoutes from "./Cameras/CameraRoutes";
import MapRoutes from "./Map/MapRoutes";
import {useAuthProvider} from "Providers/AuthProvider";
import {AccessLevel} from "../Features/Users/Services/UserDto";
import CustomerViewRoutes from "./CustomerViews/CustomerViewRoutes";

const ApplicationRoutes = () => {
    
    const { t } = useTranslation();
    
    const { getCustomClaim } = useAuthProvider();
    
    const authenticatedRoutes = () => {
        if (getCustomClaim('AccessLevel')  >= AccessLevel.Employee) {
            return (
                <DefaultLayout menuItems={[
                    {
                        label: t('menu.map'),
                        to: "/map"
                    },
                    {
                        label: t('menu.blocks'),
                        to: "/blocks"
                    },
                    {
                        label: t('menu.cameras'),
                        to: "/cameras"
                    },
                    {
                        label: t('menu.customers'),
                        to: "/customers"
                    },
                    {
                        label: t('menu.users'),
                        to: "/users"
                    }
                ]}>
                    <Routes>
                        <Route path={"/map/*"} element={<MapRoutes />} />
                        <Route path={"/blocks/*"} element={<BlockRoutes />} />
                        <Route path={"/cameras/*"} element={<CameraRoutes />} />
                        <Route path={"/customers/*"} element={<CustomerRoutes />} />
                        <Route path={"/users/*"} element={<UserRoutes />} />

                        <Route path={"*"} element={<Navigate to={"/map"}/>}/>
                    </Routes>
                </DefaultLayout>
            )
        } else {
            return (
                <DefaultLayout menuItems={[
                    {
                        label: t('menu.map'),
                        to: "/map"
                    },
                    {
                        label: t('menu.blocks'),
                        to: "/blocks"
                    }
                ]}>
                    <Routes>
                        <Route path={"*"} element={<CustomerViewRoutes />} />
                    </Routes>
                </DefaultLayout>
            )
        }
    }
    
    return (
        <Routes>
    
            <Route path={"/signin"} element={
                <RedirectIfLoggedIn>
                    <SignIn />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={"/resetpassword"} element={
                <RedirectIfLoggedIn>
                    <ResetPassword />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={"/auth/action"} element={
                <RedirectIfLoggedIn>
                    <AuthActions />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={"*"} element={
                <RequireAuthentication>
                    {authenticatedRoutes()}
                </RequireAuthentication>
            }>
            </Route>
        </Routes>
    )
}

export default ApplicationRoutes
