import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {CustomerDto, MutateCustomerDto} from "./CustomerDto";
import {PaginatedList} from "../../../Apis/Models/PaginatedList";
import {QueryParams} from "../../../Apis/Models/QueryParams";

export const CUSTOMERS_CACHE_KEY = 'CUSTOMERS_CACHE_KEY'
export const useCustomers = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<CustomerDto>, unknown>([CUSTOMERS_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<CustomerDto>>({
            url: `/customers`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useCustomer = (id: number) => {
    return useQuery<CustomerDto, unknown>([CUSTOMERS_CACHE_KEY, id], async () => {
        return await request<CustomerDto>({
            url: `/customers/${id}`,
            method: 'GET',
        })});
}

export const useMutateCustomer = () => {
    const queryClient = useQueryClient();

    return useMutation(async (customer: MutateCustomerDto) => {
        if (!customer.id) {
            return await request({
                url: `/customers`,
                method: 'POST',
                data: customer
            })
        } else {
            return await request({
                url: `/customers/${customer.id}`,
                method: 'PUT',
                data: customer
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CUSTOMERS_CACHE_KEY])
        }
    })
}

export const useDeleteCustomer = () => {
    const queryClient = useQueryClient();

    return useMutation(async (customer: CustomerDto) => {
        return await request({
            url: `/customers/${customer.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([CUSTOMERS_CACHE_KEY])
        }
    })
}