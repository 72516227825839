import {Navigate, Route, Routes} from "react-router-dom";
import BlockTypesCreate from "Pages/Blocks/BlockTypes/Create";
import BlockTypesEdit from "Pages/Blocks/BlockTypes/Edit";
import BlockTypesIndex from "Pages/Blocks/BlockTypes/Index";
import BlockSubscriptionsCreate from "Pages/Blocks/BlockSubscriptions/Create";
import BlockSubscriptionsEdit from "Pages/Blocks/BlockSubscriptions/Edit";
import BlockSubscriptionsIndex from "Pages/Blocks/BlockSubscriptions/Index";
import BlockServiceCreate from "Pages/Blocks/BlockServices/Create";
import BlockServiceCreateForBlock from "Pages/Blocks/BlockServices/CreateForBlock";
import BlockServiceEdit from "Pages/Blocks/BlockServices/Edit";
import BlockServiceIndex from "Pages/Blocks/BlockServices/Index";
import Create from "Pages/Blocks/Create";
import Edit from "Pages/Blocks/Edit";
import Index from "Pages/Blocks/Index";

const BlockRoutes = () => {

    return (
        <Routes>
            <Route path={`types/create`} element={<BlockTypesCreate/>}/>
            <Route path={`types/edit/:id`} element={<BlockTypesEdit/>}/>
            <Route path={`types`} element={<BlockTypesIndex/>}/>

            <Route path={`subscriptions/create`} element={<BlockSubscriptionsCreate/>}/>
            <Route path={`subscriptions/edit/:id`} element={<BlockSubscriptionsEdit/>}/>
            <Route path={`subscriptions`} element={<BlockSubscriptionsIndex/>}/>

            <Route path={`services/create`} element={<BlockServiceCreate/>}/>
            <Route path={`services/create/block/:id`} element={<BlockServiceCreateForBlock />}/>
            <Route path={`services/edit/:id`} element={<BlockServiceEdit/>}/>
            <Route path={`services`} element={<BlockServiceIndex/>}/>

            <Route path={`create`} element={<Create/>}/>
            <Route path={`edit/:id`} element={<Edit/>}/>
            <Route path={`/`} element={<Index/>}/>

            <Route path={"*"} element={<Navigate to={"/blocks"}/>}/>
        </Routes>
    )

}

export default BlockRoutes