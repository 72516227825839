import List from "Components/List";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {UserDto} from "../Services/UserDto";
import {useAccessLevels} from "../Hooks/UseAccessLevels";
import Clickable from "../../../Components/BaseComponents/Clickable";
import {FaPen, FaTrash} from "react-icons/fa6";
import Gap from "Components/BaseComponents/Gap";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import { useDeleteUser } from "../Services/UsersApi";
import useToasts from "../../../Hooks/UseToasts";

interface Props {
    data?: UserDto[];
    isLoading: boolean;
}
const UserList = (props: Props) => {

    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const { showSuccessToast } = useToasts();

    
    const { getLabel } = useAccessLevels();
    
    const { mutateAsync, isLoading: isDeletingUser } = useDeleteUser();
    
    const deleteUser = async (user: UserDto) => {
        await mutateAsync(user);

        showSuccessToast(t('userDeleted'));
    }
    
    return (
        <>
            <List<UserDto> data={props.data} loading={props.isLoading} columns={[
                {
                    title: t('name'),
                    textRender: (record) => record.name
                },
                {
                    title: t('email'),
                    textRender: (record) => record.email
                },
                {
                    title: t('accessLevel'),
                    textRender: (record) => getLabel(record.accessLevel)
                },
                {
                    title: t('functions'),
                    size: "min-content",
                    customRender: (record) => (
                            <Gap>
                                <Clickable onClick={() => navigate(`/users/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                                <ConfirmationDialog onConfirm={() => deleteUser(record)} loading={isDeletingUser} buttonType={'danger'} title={t('areYouSure')}>
                                    <Clickable><FaTrash />{t('delete')}</Clickable>
                                </ConfirmationDialog>
                            </Gap>
                        )    
                }
            ]} keyExtractor={(record) => record.id.toString()} />
        </>
    )

}

export default UserList