import {PaginatedList} from "Apis/Models/PaginatedList";
import React from "react";
import Flex from "./BaseComponents/Flex";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa6";
import styled, { css } from "styled-components";

interface IProps {
    paginatedList?: PaginatedList<any>;
    onPageChange: (newPage: number) => void
}
const Pagination = (props: IProps) => {

    const { paginatedList, onPageChange } = props;

    if (!paginatedList || paginatedList.totalPages === 0) {
        return (
            <></>
        )
    }

    const { page, totalPages } = paginatedList;

    const pageItem = (pageNumber: number) => {
        return (
            <PaginationItem $active={page === pageNumber} onClick={() => onPageChange(pageNumber)}>
                {pageNumber + 1}
            </PaginationItem>
        )
    }

    const pages = Array.from(Array(totalPages).keys());

    if (totalPages === 1) {
        return (
            <Flex $justifyCenter >
                {pageItem(0)}
            </Flex>
        )
    }
    
    const shouldRenderPoint = ( pageToRender: number ) => {
        let shouldRender = false;
        
        shouldRender = !(page + 5 <= pageToRender || page - 5 >= pageToRender)
        
        if (!shouldRender) {
            shouldRender = pageToRender === totalPages - 1 || pageToRender === 0
        }
        
        return shouldRender;
    }

    return (
        <Flex $justifyCenter >
            <Flex $alignCenter style={{gap: 10}}>
                {page > 0 && totalPages > 0 && (
                    <FaCaretLeft onClick={() => onPageChange(page - 1)} />
                )}
                {pages.map((pageIndex, index) => (
                    <React.Fragment key={pageIndex}>
                        {shouldRenderPoint(pageIndex) && (
                            <React.Fragment key={pageIndex}>
                                {pageItem(pageIndex)}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ))}
                {page < (totalPages - 1) && (
                    <FaCaretRight onClick={() => onPageChange(page + 1)} />
                )}
            </Flex>
        </Flex>
    )
}

const PaginationItem = styled.div<{$active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.spacing(3)};
  width: ${props => props.theme.spacing(3)};
  border: ${props => props.theme.dimensions.borderWidth} solid ${props => props.theme.colors.primaryText};
  cursor: pointer;

  ${props => props.$active && css`
    background-color: ${props => props.theme.colors.primaryText};
    color: white
  `}
`

export default Pagination