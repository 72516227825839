import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {request} from "Apis/BaseApi";
import {BlockDto, MutateBlockDto} from "./BlockDto";
import {QueryParams} from "../../../Apis/Models/QueryParams";
import {PaginatedList} from "../../../Apis/Models/PaginatedList";

export const BLOCK_CACHE_KEY = 'BLOCK_CACHE_KEY'
export const useBlocks = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<BlockDto>, unknown>([BLOCK_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<BlockDto>>({
            url: `/blocks`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const UPCOMMING_SERVICE_BLOCK_CACHE_KEY = 'UPCOMMING_SERVICE_BLOCK_CACHE_KEY'
export const useUpcommingServiceBlocks = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<BlockDto>, unknown>([BLOCK_CACHE_KEY, queryable], async () => {
        return await request<PaginatedList<BlockDto>>({
            url: `/blocks/upcommingService`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useCustomerViewBlocks = (queryable: Partial<QueryParams> | null = null, requireQuery: boolean = false) => {
    return useQuery<PaginatedList<BlockDto>, unknown>([BLOCK_CACHE_KEY, 'CUSTOMER_VIEW', queryable], async () => {
        return await request<PaginatedList<BlockDto>>({
            url: `/customerView/blocks`,
            method: 'GET',
            queryParams: queryable
        })}, {
        enabled: !requireQuery || (!!queryable && Object.keys(queryable).length !== 0)
    });
}

export const useBlock = (id: number) => {
    return useQuery<BlockDto, unknown>([BLOCK_CACHE_KEY, id], async () => {
        return await request<BlockDto>({
            url: `/blocks/${id}`,
            method: 'GET',
        })});
}

export const useMutateBlock = () => {
    const queryClient = useQueryClient();

    return useMutation(async (block: MutateBlockDto) => {
        if (!block.id) {
            return await request({
                url: `/blocks`,
                method: 'POST',
                data: block
            })
        } else {
            return await request({
                url: `/blocks/${block.id}`,
                method: 'PUT',
                data: block
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_CACHE_KEY])
        }
    })
}

export const useDeleteBlock = () => {
    const queryClient = useQueryClient();

    return useMutation(async (block: BlockDto) => {
        return await request({
            url: `/blocks/${block.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([BLOCK_CACHE_KEY])
        }
    })
}