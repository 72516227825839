import {Navigate, Route, Routes} from "react-router-dom";
import Blocks from "./Blocks";
import Cameras from "./Cameras";
import BlockServiceMap from "./BlockServiceMap";

const MapRoutes = () => {

    return (
        <Routes>
            <Route path={`blocks`} element={<Blocks />}/>
            <Route path={`serviceMap`} element={<BlockServiceMap />}/>
            <Route path={`cameras`} element={<Cameras />}/>

            <Route path={"*"} element={<Navigate to={"/map/blocks"}/>}/>
        </Routes>
    )

}

export default MapRoutes