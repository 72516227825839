import styled from "styled-components";
import Menu, {MenuItemProps} from "./Menu";
import {useAuthProvider} from "../Providers/AuthProvider";
import React from "react";
import ConfirmationDialog from "Components/ConfirmationDialog";
import {useQueryClient} from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface Props {
    children: any;
    menuItems: MenuItemProps[]
}

const DefaultLayout = (props: Props) => {
    
    const { t } = useTranslation();
    
    const { userContext, signOut  } = useAuthProvider();
    const queryClient = useQueryClient();
    
    
    const onSignout = () => {
        signOut();
        queryClient.invalidateQueries();
    }
    
    const end = () => {
        return (
            <ConfirmationDialog onConfirm={onSignout} buttonType={'danger'} title={t('logoutHeader')} confirmText={t('signOut')}>
                <Logout>{userContext?.name}</Logout>
            </ConfirmationDialog>
        )
    }
    
    return (
        <OuterContainer>
            <TopBar>
                <InnerContainer>
                </InnerContainer>
            </TopBar>
            <InnerContainer>
                <Menu before={<img alt={"logo"} width={68} height={44} src={"/Images/Logo.png"} />} menuItems={props.menuItems} end={end()} />
                
                <ContentContainer>
                    {props.children}
                </ContentContainer>
            </InnerContainer>
        </OuterContainer>
    )
}

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100vw;
  min-height: 100vh;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
`

const Logout = styled.div`
  margin-bottom: ${props => props.theme.spacing(1)}
`

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${props => props.theme.spacing(1)};
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.colors.light};
`

const ContentContainer = styled.div`
    padding-top: ${props => props.theme.spacing(2)};
`
    

export default DefaultLayout