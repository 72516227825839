import {H1} from "Components/BaseComponents/Heading";

import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import BlockForm from "../../Features/Blocks/Components/BlockForm";

const Create = () => {

    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <>
            <H1 style={{paddingBottom: 10}}>{t('createBlock')}</H1>

            <BlockForm onSubmit={() => navigate(-1)} />
        </>
    )

}

export default Create