import React from "react";
import Flex from "Components/BaseComponents/Flex";
import { useTranslation } from "react-i18next";
import {H1} from "../../Components/BaseComponents/Heading";
import Menu from "../../Layouts/Menu";
import Gap from "Components/BaseComponents/Gap";
import {useCameras} from "../../Features/Cameras/Services/CameraApi";
import {CameraDto} from "../../Features/Cameras/Services/CameraDto";
import useQueryParams from "../../Hooks/UseQueryParams";
import StyledInput from "../../Components/BaseComponents/StyledInput";
import MarkerMap from "../../Components/MarkerMap";

const Cameras = () => {

    const { t } = useTranslation();
    const [ filter, setFilter ] = useQueryParams({active: true});

    const { data } = useCameras(filter);

    const getContentString = (camera: CameraDto) => {
        return '<div>' +
            `<div style="padding-bottom: 5px; font-weight: bold">${camera.serialNumber}</div>` +
            `<div style="padding-bottom: 5px;">${camera.address}, ${camera.zipCode} ${camera.city}</div>` +
            `<div style="padding-bottom: 5px;"><a style="display: flex; gap: 5px;" href="/cameras/edit/${camera.id}"><img src="/Images/pen.png" height="15" width="15" alt="edit" /> ${t('edit')}</a></div>` +
            "</div>";
    }
    
    return (
        <>
            <Flex $spaceBetween>
                <H1>{t('map')}</H1>
            </Flex>

            <Gap $vertical>
                <Menu menuItems={[
                    {
                        label: t('menu.blocks'),
                        to: "/map/blocks"
                    },
                    {
                        label: t('menu.serviceMap'),
                        to: "/map/serviceMap"
                    },
                    {
                        label: t('menu.cameras'),
                        to: "/map/cameras"
                    }
                ]}  />

                <StyledInput $block placeholder={t('search')} defaultValue={filter?.search?.toString() ?? ''} onChange={e => setFilter({search: e.currentTarget.value})} />

                <MarkerMap
                    getMarkerTitle={block => block.serialNumber}
                    getInfoWindowContent={getContentString}
                    data={data?.items}
                />
            </Gap>
        </>
    )
}

export default Cameras