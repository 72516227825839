import Flex from "Components/BaseComponents/Flex";
import LoginForm from "Features/Authentication/Components/LoginForm";

const SignIn = () => {
    
    return (
        <Flex style={{height: '100vh'}} $justifyCenter $alignCenter>
            <div style={{width: 550}}>
                <LoginForm />
            </div>
        </Flex>
    )
}


export default SignIn
