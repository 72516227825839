import React from "react";
import {Controller, ControllerFieldState, FieldError} from "react-hook-form";
import Checkbox from "./Checkbox";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    placeholder: string | undefined | any;
    defaultValue?: string;
    error?: FieldError | undefined;
    control: any;
    rules?: any
}
const CheckboxWithLabel = (props: Props) => {

    const errorMessage = (fieldState: ControllerFieldState) => {
        if (fieldState?.error?.message) {
            return fieldState?.error?.message;
        }

    }

    return (
        <Controller name={props.name} defaultValue={props.defaultChecked} control={props.control} rules={props.rules} render={({ field, fieldState }) => (
            <Checkbox label={props.label} placeholder={props.placeholder} hasError={!!fieldState.error} secondaryLabel={errorMessage(fieldState)} checked={field.value} onCheckedChange={field.onChange} {...field} />
        )}
        />
    )
}

export default CheckboxWithLabel