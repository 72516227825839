import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {DawaAutocompleteModel} from "./Models/DawaAutocompleteModel";

export const ADDRESS_SEARCH_CACHE_KEY = "ADDRESS_SEARCH_CACHE_KEY"
export const useAddressSearchQuery = (query: string) => {
    return useQuery<DawaAutocompleteModel[]>([ADDRESS_SEARCH_CACHE_KEY, query], async () => {
        const client = axios.create({
            baseURL: 'https://dawa.aws.dk/adresser/autocomplete'
        });

        const response = await client({
            method: 'GET',
            url: `?q=${query}`
        });

        return response.data;
    }, {
        enabled: !!query
    })
}
