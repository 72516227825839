import { H1 } from "Components/BaseComponents/Heading";
import React from "react";
import { useTranslation } from "react-i18next";
import {useParams} from "react-router-dom";
import ContentLoader from "../../Components/ContentLoader";
import {useCustomerViewBlockService} from "../../Features/Blocks/Services/BlockServiceApi";
import CustomerViewShowService from "../../Features/Blocks/Components/CustomerViewShowService";
import {useSchemaDefinitions} from "../../Features/Schema/Services/SchemaDefinitionApi";

const BlocksServices = () => {

    const { t } = useTranslation();

    const { id } = useParams();

    const { data, isLoading } = useCustomerViewBlockService(parseInt(id!))
    const { data: schemas, isLoading: isFetchingSchemas } = useSchemaDefinitions();
    
    return (
        <ContentLoader shown={isLoading || isFetchingSchemas}>
            <H1 style={{paddingBottom: 10}}>{t('serviceReport')}</H1>

            <CustomerViewShowService entity={data!} schemaDefinition={schemas?.find(x => x.id === data?.schema.schemaDefinitionId)!} />
        </ContentLoader>
    )
}


export default BlocksServices