import {Navigate, Route, Routes} from "react-router-dom";
import CameraLocationCreate from "./CameraLocations/Create";
import CameraLocationEdit from "./CameraLocations/Edit";
import CameraLocationIndex from "./CameraLocations/Index";
import Create from "./Create";
import Edit from "./Edit";
import Index from "./Index";

const CameraRoutes = () => {
    
    return (
        <Routes>
            <Route path={`locations/create`} element={<CameraLocationCreate />}/>
            <Route path={`locations/edit/:id`} element={<CameraLocationEdit />}/>
            <Route path={`locations`} element={<CameraLocationIndex />}/>

            <Route path={`create`} element={<Create />}/>
            <Route path={`edit/:id`} element={<Edit />}/>
            <Route path={`/`} element={<Index />}/>

            <Route path={"*"} element={<Navigate to={"/cameras"}/>}/>
        </Routes>
    )
}

export default CameraRoutes;