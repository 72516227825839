import { createContext, useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { auth } from "FirebaseSetup/auth";
import PageLoader from "Components/PageLoader";
import {useSendResetPassword, useUserContext } from "Apis/UserContextApi";
import { IUserContextDto } from "Apis/Models/IUserContextDto";
import 'firebase/compat/auth';
import { User as FirebaseUser } from "firebase/auth";
import useToasts from "../Hooks/UseToasts";
import {useTranslation} from "react-i18next";

type ContextProps = {
    firebaseUser: FirebaseUser | null,
    userContext: IUserContextDto | null | undefined,
    forceReload: Function;
    signIn: Function;
    resetPassword: Function;
    signOut: Function;
    getCustomClaim: Function;
}
export const AuthContextProvider = createContext<ContextProps>({
    firebaseUser: null,
    userContext: undefined,
    forceReload: () => {
        throw new Error("Method not implemented")
    },
    signIn: () => {
        throw new Error("Method not implemented")
    },
    resetPassword: () => {
        throw new Error("Method not implemented")
    },
    signOut: () => {
        throw new Error("Method not implemented")
    },
    getCustomClaim: () => {
        throw new Error("Method not implemented")
    }
});
interface Props {
    children: React.ReactNode;
}
const AuthProvider = (props: Props) => {

    const { t } = useTranslation();

    const { showSuccessToast, showErrorToast } = useToasts();

    const [loading, setLoading] = useState<boolean>(true);
    const [firebaseUser, setFirebaseUser] = useState<any>(null);
    const [claims, setClaims] = useState<{ [key: string]: any; }>();
    
    const {data: userContext, refetch, isFetching: isFetchingUserContext} = useUserContext(!!firebaseUser);
    const { mutateAsync: sendResetPassword } = useSendResetPassword();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setFirebaseUser(user);
            
            if (!!user) {
                user?.getIdTokenResult().then((result) => {
                    setClaims(result.claims);
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(false)
            }
        })
    }, [])
    
    const signIn = async (data: {email: string, password: string}) => {
        try {
            await auth.signInWithEmailAndPassword(data.email, data.password);
        } catch (e) {
            showErrorToast(t('invalidCredentials'));
            return;
        }

        await refetch();
    }
    
    const resetPassword = async (data: {email: string}) => {
        try {
            await sendResetPassword(data.email);
        } catch (e) {
            showErrorToast(t('anErrorOccurred'));
        }

        showSuccessToast(t('resetPasswordEmailSent'));
    }

    const signOut = async () => {
        await auth.signOut();
    }
    
    const forceReload = useCallback(async () => {
        await auth.currentUser?.getIdToken(true);
        
        await refetch();
    }, [refetch]);
    
    const getCustomClaim = (claim: 'AccessLevel') => {
        return claims?.[claim];
    }
    
    
    useEffect(() => {
        if (!!firebaseUser) {
            forceReload();
        }
    }, [firebaseUser, forceReload])
    
    return (
        <AuthContextProvider.Provider value={{firebaseUser, userContext, forceReload, signIn, resetPassword, signOut, getCustomClaim}}>
            <PageLoader shown={loading || isFetchingUserContext}>
                {props.children}
            </PageLoader>
        </AuthContextProvider.Provider>
    )
}

export default AuthProvider

export const useAuthProvider = () => {
    return useContext(AuthContextProvider);
}
