import {BlockServiceDto} from "../Services/BlockServiceDto";
import React from "react";
import { useTranslation } from "react-i18next";
import {useCustomerViewBlocks} from "../Services/BlockApi";
import {useCustomerViewUsers} from "../../Users/Services/UsersApi";
import moment from "moment";
import styled from "styled-components";
import SchemaRender from "../../Schema/Components/SchemaRender";
import {SchemaDefinitionDto} from "../../Schema/Services/SchemaDefinitionDto";
import Flex from "../../../Components/BaseComponents/Flex";

interface Props {
    entity: BlockServiceDto;
    schemaDefinition: SchemaDefinitionDto;
}
const CustomerViewShowService = (props: Props) => {
    
    const { entity } = props;
    
    const { t } = useTranslation();
    const { data: blocks } = useCustomerViewBlocks();
    const { data: employees } = useCustomerViewUsers();

    const block = blocks?.items.find(x => x.id === entity.blockId);
    const responsible = employees?.items.find(x => x.id === entity.employeeId);
    
    return (
        <Container>
            <Flex $spaceBetween>
                <Label>
                    {t('serviceDate')}
                </Label>
                {moment(entity.serviceDate).format('DD / MM / YYYY')}
            </Flex>

            <Flex $spaceBetween>
                <Label>
                    {t('handledBy')}
                </Label>
                {responsible?.name}
            </Flex>
            
            <Flex $spaceBetween>
                <Label>
                    {t('address')}
                </Label>
                {`${block?.address} ${block?.city} ${block?.zipCode}`}
            </Flex>

            <Flex $spaceBetween>
                <Label>
                    {t('blockSerialNumber')}
                </Label>
                {block?.serialNumber}
            </Flex>

            <Flex $spaceBetween>
                <Label>
                    {t('nextService')}
                </Label>
                {moment(entity.nextService).format('DD / MM / YYYY')}
            </Flex>

            <SchemaRender schemaDefinition={props.schemaDefinition} schema={props.entity.schema} />
        </Container>
    )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  > div {
    padding: ${props => props.theme.spacing(1)};
  }

  > :nth-child(odd) {
    background-color: ${props => props.theme.colors.light};
  }

`

const Label = styled.div`
  font-weight: bold;
`

export default CustomerViewShowService