import { toast } from "react-toastify";

const useToasts = (): { showSuccessToast: Function, showErrorToast: Function} => {

    const showSuccessToast = (message: string) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "colored",
        });
    }
    
    const showErrorToast = (message: string) => {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "colored",
        });
    }
    
    return { showSuccessToast, showErrorToast }
}

export default useToasts
