import Spinner from './Spinner';
import styles from "./ContentLoader.module.css";

interface Props {
    shown: boolean;
    children: any;
}
const ContentLoader = ({shown, children}: Props) => {

    return (
        <div style={{position: "relative"}}>
            <div className={`${styles.pageLoader} ${!shown ? styles.hidden :''}`}>
                <Spinner height={75} width={75} />
            </div>
            <div className={`${styles.content} ${shown ? styles.hidden :''}`}>
                {!shown && (
                    <>
                        {children}
                    </>
                )}
            </div>
        </div>
    )
}

export default ContentLoader
