import List from "Components/List";
import { CustomerDto } from "Features/Customers/Services/CustomerDto";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FaPen, FaTrash} from "react-icons/fa6";
import Clickable from "Components/BaseComponents/Clickable";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import {useDeleteCustomer} from "../Services/CustomerApi";
import Gap from "Components/BaseComponents/Gap";
import useToasts from "../../../Hooks/UseToasts";

interface Props {
    data?: CustomerDto[];
    isLoading: boolean;
}
const CustomerList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast } = useToasts();

    const { mutateAsync: deleteCustomerMutation, isLoading: isDeleting } = useDeleteCustomer();
    
    const deleteCustomer = async (customer: CustomerDto) => {
        await deleteCustomerMutation(customer)

        showSuccessToast(t('customerDeleted'));
    }
    
    
    return (
        <List<CustomerDto> data={props.data} loading={props.isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('vatNumber'),
                textRender: (record) => record.vatNumber
            },
            {
                title: t('address'),
                textRender: (record) => `${record.address} ${record.city} ${record.zipCode}`
            },
            {
                title: t('phone'),
                textRender: (record) => record.phone
            },
            {
                title: t('email'),
                textRender: (record) => record.email
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                    <Gap>
                        <Clickable onClick={() => navigate(`/customers/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                        <ConfirmationDialog loading={isDeleting} onConfirm={() => deleteCustomer(record)} buttonType={'danger'} title={t('areYouSure')}>
                            <Clickable><FaTrash />{t('delete')}</Clickable>
                        </ConfirmationDialog>
                    </Gap>
                ) 
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default CustomerList