import {useTranslation} from "react-i18next";
import useQueryParams from "../../Hooks/UseQueryParams";
import {BlockApi} from "../../Features/Blocks";
import Flex from "../../Components/BaseComponents/Flex";
import {H1} from "../../Components/BaseComponents/Heading";
import Gap from "../../Components/BaseComponents/Gap";
import Menu from "../../Layouts/Menu";
import StyledInput from "../../Components/BaseComponents/StyledInput";
import Pagination from "../../Components/Pagination";
import React from "react";
import CustomerViewBlocksList from "../../Features/Blocks/Components/CustomerViewBlocksList";
import { useAuthProvider } from "Providers/AuthProvider";
import { AccessLevel } from "Features/Users/Services/UserDto";

const Blocks = () => {

    const { t } = useTranslation();
    const { getCustomClaim } = useAuthProvider();

    const [ filter, setFilter ] = useQueryParams({page: 0, pageSize: 25});

    const { data, isLoading } = BlockApi.useCustomerViewBlocks(filter, true);

    let menuItems = [
        {
            label: t('menu.blocks'),
            to: "/blocks",
            exact: true
        },
    ]

    if (getCustomClaim('AccessLevel') === AccessLevel.Customer) {
        menuItems.push({
            label: t('menu.blockServices'),
            to: "/blocks/services",
            exact: false
        })
    }

    return (
        <>
            <Flex $spaceBetween>
                <H1>{t('blocks')}</H1>
            </Flex>

            <Gap $vertical>
                <Menu menuItems={menuItems}  />

                <StyledInput $block placeholder={t('search')} defaultValue={filter?.search?.toString() ?? ''} onChange={e => setFilter({search: e.currentTarget.value})} />

                <CustomerViewBlocksList data={data?.items} isLoading={isLoading}  />

                <Pagination paginatedList={data} onPageChange={newPage => setFilter({page: newPage})} />
            </Gap>
        </>
    )

}

export default Blocks