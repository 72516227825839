import { request } from "Apis/BaseApi";
import {useQuery} from "@tanstack/react-query";
import {SchemaDefinitionDto, SchemaName} from "./SchemaDefinitionDto";

export const SCHEMA_DEFINITIONS_CACHE_KEY = 'SCHEMA_DEFINITIONS_CACHE_KEY';
export const useSchemaDefinitions = () => {
    return useQuery<SchemaDefinitionDto[]>([SCHEMA_DEFINITIONS_CACHE_KEY], async () => {
        return await request<SchemaDefinitionDto[]>({
            url: `/schemaDefinitions`,
            method: 'GET',
        })
    });
}

export const CURRENT_SCHEMA_DEFINITIONS_CACHE_KEY = 'CURRENT_SCHEMA_DEFINITIONS_CACHE_KEY';
export const useCurrentSchemaDefinitions = (schemaName?: SchemaName) => {
    return useQuery<SchemaDefinitionDto[]>([CURRENT_SCHEMA_DEFINITIONS_CACHE_KEY, ], async () => {
        return await request<SchemaDefinitionDto[]>({
            url: `/schemaDefinitions/current?schemaName=${schemaName}`,
            method: 'GET',
        })
    });
}